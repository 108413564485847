var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import "../styles/SubscriberActivity.css";
import Modal from 'react-bootstrap/Modal';
import useAuth from "../../App/hooks/useAuth";
function SubscriberActivityReport(props) {
    function formatDateTime(dateTimeFormatted) {
        var _a = dateTimeFormatted.split(' '), datePart = _a[0], timePart = _a[1];
        var _b = datePart.split('/'), day = _b[0], month = _b[1], year = _b[2];
        var _c = timePart.split(':'), hours = _c[0], minutes = _c[1], seconds = _c[2];
        var dateString = "".concat(year, "-").concat(month, "-").concat(day, "T").concat(hours, ":").concat(minutes, ":").concat(seconds);
        var dateTime = new Date(dateString);
        var dateFormatted = dateTime.toLocaleDateString();
        var timeFormatted = dateTime.toLocaleTimeString();
        return { dateFormatted: dateFormatted, timeFormatted: timeFormatted };
    }
    var auth = useAuth().auth;
    function filterHistory(subscriberHistory, auth) {
        return subscriberHistory.reduce(function (accumulator, current) {
            var isRestrictedUser = auth && (auth.id === 173 || auth.id === 45);
            var serviceContainsAlares = current.service.toLowerCase().includes('alares');
            if (serviceContainsAlares && !isRestrictedUser) {
                return accumulator;
            }
            var lastService = accumulator[accumulator.length - 1];
            if (!lastService || lastService.service !== current.service || ![1, 5, 10].includes(current.token_status_id)) {
                accumulator.push(current);
            }
            else if ([1, 5, 10].includes(current.token_status_id)) {
                accumulator[accumulator.length - 1] = current;
            }
            return accumulator;
        }, []);
    }
    ;
    var filteredHistory = filterHistory(props.subscriberHistory, auth);
    return (_jsx(_Fragment, { children: _jsxs(Modal, __assign({}, props, { size: "lg", "aria-labelledby": "contained-modal-title-vcenter", centered: true, className: "modal-subscriber-activity-report" }, { children: [_jsx(Modal.Header, __assign({ className: "title-modal" }, { children: _jsxs(Modal.Title, __assign({ id: "contained-modal-title-vcenter" }, { children: ["Relat\u00F3rio de Atividades do Assinante ", _jsxs("strong", { children: [" ", props.subscriberNameReport] })] })) })), _jsx(Modal.Body, __assign({ className: "box-modal-body" }, { children: _jsxs("table", __assign({ className: "table table-hover table-bills container-table-subscriber-activity" }, { children: [_jsx("thead", __assign({ id: "border-thead" }, { children: _jsxs("tr", { children: [_jsx("th", __assign({ className: "col-3 titles-table-subscriber-activity", style: { position: "relative", left: "1rem" } }, { children: "Data" })), _jsx("th", __assign({ className: "col-2 titles-table-subscriber-activity", style: { position: "relative", left: "0.4rem" } }, { children: "Hora" })), _jsx("th", __assign({ className: "col-4 titles-table-subscriber-activity", style: { position: "relative", left: "4.5rem" } }, { children: "Ocorr\u00EAncia" })), _jsx("th", __assign({ className: "col-3 titles-table-subscriber-activity", style: { position: "relative", left: "3.2rem" } }, { children: "Servi\u00E7o" }))] }) })), _jsx("tbody", { children: filteredHistory && filteredHistory.length > 0 ? (filteredHistory.map(function (subscriber, index) {
                                    var _a = formatDateTime(subscriber.date), dateFormatted = _a.dateFormatted, timeFormatted = _a.timeFormatted;
                                    return (_jsxs("tr", { children: [_jsx("td", __assign({ className: "line-infos-subscriber-activity" }, { children: dateFormatted })), _jsx("td", __assign({ className: "line-infos-subscriber-activity" }, { children: timeFormatted })), _jsx("td", __assign({ className: "line-infos-subscriber-activity align-items-center text-center" }, { children: _jsxs("div", __assign({ className: "d-flex justify-content-center gap-1" }, { children: [_jsx("span", __assign({ className: "material-symbols-outlined material-design-icon", style: { color: subscriber === null || subscriber === void 0 ? void 0 : subscriber.token_status_color } }, { children: subscriber.token_status_icon_name })), _jsx("span", { children: subscriber.token_status_translation })] })) })), _jsx("td", __assign({ className: "line-infos-subscriber-activity text-center" }, { children: subscriber.service }))] }, index));
                                })) : (_jsx("tr", { children: _jsx("td", __assign({ colSpan: 4, className: "text-center" }, { children: "Nenhum hist\u00F3rico de atividades dispon\u00EDvel." })) })) })] })) })), _jsx(Modal.Footer, __assign({ className: "modal-footer" }, { children: _jsx("button", __assign({ onClick: props.closeModal, className: "close-button" }, { children: "Fechar" })) }))] })) }));
}
export default SubscriberActivityReport;
