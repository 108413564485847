var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Footer from '../../App/components/Footer';
import { useNavigate } from "react-router-dom";
import passwordSucess from "../../App/assets/sucess-password.png";
import "../styles/PasswordChangedSuccess.css";
function PasswordChangedSuccess() {
    var navigate = useNavigate();
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "container container-password-changed-success" }, { children: [_jsx("img", { src: passwordSucess, alt: "Imagem de sucesso", className: "img-success" }), _jsx("h2", { children: "Senha cadastrada com sucesso!" }), _jsxs("p", { children: ["Tudo pronto! Agora basta clicar no bot\u00E3o abaixo para prosseguir ", _jsx("br", {}), "para o painel principal da plataforma."] }), _jsx("button", __assign({ className: "orange-button", onClick: function () { return navigate('/login'); } }, { children: "Prosseguir" }))] })), _jsx(Footer, {})] }));
}
export default PasswordChangedSuccess;
