var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import "../../App/styles/HomeAdmin.css";
import Title from "../../App/components/Title";
var HomeAdmin = function () {
    return (_jsx("div", __assign({ className: "content-internal-padding" }, { children: _jsx(Title, { text: "Hello World" }) })));
};
export default HomeAdmin;
