var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReportService from "../../Report/services/ReportService";
import Pie from "../../App/components/Pie";
import "../../App/styles/HomeClient.css";
import "../../App/styles/App.css";
import CircularProgress from "@mui/material/CircularProgress";
import Title from "../../App/components/Title";
import "../../App/styles/GlobalStyle.css";
import useAuth from "../../App/hooks/useAuth";
import { handleServiceError } from "../../App/components/ErrorHandler";
var HomeClient = function () {
    var navigate = useNavigate();
    var _a = useState([]), pieSubscribers = _a[0], setPieSubscribers = _a[1];
    var _b = useState([]), pieTokens = _b[0], setPieTokens = _b[1];
    var _c = useState(0), totalSubscribers = _c[0], setTotalSubscribers = _c[1];
    var _d = useState(0), activeTokens = _d[0], setActiveTokens = _d[1];
    var _e = useState(0), totalBilling = _e[0], setTotalBilling = _e[1];
    var _f = useState(0), totalProvisioned = _f[0], setTotalProvisioned = _f[1];
    var _g = useState(true), loadPieSubscribers = _g[0], setLoadPieSubscribers = _g[1];
    var _h = useState(true), loadPieTokens = _h[0], setloadPieTokens = _h[1];
    var _j = useState(0), activeTokensGlobo = _j[0], setActiveTokensGlobo = _j[1];
    var _k = useState([0]), tokenData = _k[0], setTokenData = _k[1];
    var _l = useState(true), emptyChart = _l[0], setEmptyChart = _l[1];
    var auth = useAuth().auth;
    var userId = auth === null || auth === void 0 ? void 0 : auth.id;
    var calculateWidth = function (value) {
        var minValue = 0;
        var maxValue = 500;
        if (value < 1) {
            var minorthan1 = 0;
            return "".concat(minorthan1, "%");
        }
        var limitValue = Math.min(value, maxValue);
        var range = maxValue - minValue;
        var widthPercentage = ((limitValue - minValue) / range) * 100;
        return "".concat(widthPercentage, "%");
    };
    useEffect(function () {
        if (!auth.permissions.includes('viewAny-subscriber') &&
            auth.permissions.includes('arts-upload')) {
            navigate('upload_arts', { replace: true });
        }
        else {
            getSubscribersData();
            getTokensData();
        }
    }, []);
    function getSubscribersData() {
        return __awaiter(this, void 0, void 0, function () {
            var response, pieData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, ReportService.getSubscribersData()];
                    case 1:
                        response = _a.sent();
                        pieData = response.data.subscribers_data.data;
                        pieData.forEach(function (item) {
                            if (item.value > 0) {
                                setEmptyChart(false);
                            }
                        });
                        setTotalSubscribers(response.data.subscribers_data.total);
                        setPieSubscribers(response.data.subscribers_data.data);
                        setLoadPieSubscribers(false);
                        return [2 /*return*/];
                }
            });
        });
    }
    function getTokensData() {
        return __awaiter(this, void 0, void 0, function () {
            var response, tokenData_1, tokenIds, totalValue, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        return [4 /*yield*/, ReportService.getTokensData()];
                    case 1:
                        response = _a.sent();
                        tokenData_1 = response.data.tokens_data.data;
                        tokenIds = ["Globoplay (antigo)", "Globoplay Premium", "Globoplay sem Anúncios"];
                        totalValue = tokenIds.reduce(function (acc, id) {
                            var token = tokenData_1.find(function (item) { return item.id === id; });
                            return acc + ((token === null || token === void 0 ? void 0 : token.value) || 0);
                        }, 0);
                        setTokenData(tokenData_1);
                        setActiveTokensGlobo(totalValue);
                        setActiveTokens(response.data.tokens_data.active_tokens);
                        setPieTokens(response.data.tokens_data.data);
                        return [3 /*break*/, 4];
                    case 2:
                        error_1 = _a.sent();
                        handleServiceError(error_1);
                        return [3 /*break*/, 4];
                    case 3:
                        setloadPieTokens(false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }
    var colorsDataPieSubscribers = "#EA5A1C";
    var colorsDataPieTokens = "#1A4992";
    var totalPieSubscribers = pieSubscribers.reduce(function (total, item) { return total + item.value; }, 0);
    var totalPieTokens = pieTokens.reduce(function (total, item) { return total + item.value; }, 0);
    function renderPercentage(item, total) {
        var percentage = (item.value / total) * 100;
        var percentageStr = percentage.toFixed(2).toString().replace('.', ',');
        percentage = parseFloat(percentageStr.replace(',', '.'));
        return (_jsx("div", { children: _jsxs("p", __assign({ style: { fontSize: "20px" } }, { children: [item.value, " ", _jsxs("span", __assign({ style: { color: "#949494", fontSize: "14px" } }, { children: ["(", percentage, "%)"] }))] })) }));
    }
    var percentagesPieSubscribers = pieSubscribers.map(function (item) { return renderPercentage(item, totalPieSubscribers); });
    var percentagesPieTokens = pieTokens.map(function (item) { return renderPercentage(item, totalPieTokens); });
    return (_jsxs("div", __assign({ className: "content-internal-padding" }, { children: [_jsx(Title, { text: "Minhas estat\u00EDsticas e informa\u00E7\u00F5es" }), _jsx(_Fragment, { children: _jsx("div", __assign({ className: "col-4 mb-4 mt-4 provider-home-subscribers-activation" }, { children: tokenData.length > 0 ? (tokenData
                        .filter(function (entry) { return entry.id === "Premiere"; })
                        .map(function (entry, index) { return (_jsx("div", __assign({ className: "card" }, { children: _jsxs("div", __assign({ className: "card-body" }, { children: [_jsx("p", __assign({ style: { marginBottom: "0px" } }, { children: _jsx("strong", { children: entry.id }) })), _jsx("span", __assign({ className: "span-box" }, { children: "Margem de lucro 10%" })), _jsx("h3", { children: _jsx("strong", { children: entry.value }) }), _jsx("span", __assign({ className: "span-box" }, { children: "Usu\u00E1rios" }))] })) }), index)); })) : (_jsx("p", {})) })) }), _jsxs("div", __assign({ className: "row justify-content-between" }, { children: [_jsx("div", __assign({ className: "col mb-4 provider-home-subscribers-activation" }, { children: _jsx("div", __assign({ className: "card" }, { children: _jsxs("div", __assign({ className: "card-body" }, { children: [_jsx("h3", __assign({ style: { fontSize: "1rem" } }, { children: "Assinantes por ativa\u00E7\u00E3o" })), _jsx("div", __assign({ className: "card-graphic-pie-subscribers" }, { children: loadPieSubscribers ? _jsx(CircularProgress, {}) :
                                            emptyChart ?
                                                _jsx("div", __assign({ className: "container-empty-chart" }, { children: _jsxs("div", __assign({ className: "box-empty-chart" }, { children: [_jsx("h2", { children: "0" }), _jsx("div", __assign({ className: "box-empty-chart-legend" }, { children: _jsx("p", { children: "Os dados s\u00F3 ser\u00E3o exibidos a partir de 10 assinantes." }) }))] })) })) :
                                                _jsxs(_Fragment, { children: [_jsx(Pie, { series: pieSubscribers.map(function (item) { return item.value; }), labels: pieSubscribers.map(function (item) { return item.id; }), color: colorsDataPieSubscribers }), _jsx("div", __assign({ className: "d-flex gap-3", style: { paddingLeft: "1rem" } }, { children: percentagesPieSubscribers }))] }) }))] })) })) })), _jsx("div", __assign({ className: "col mb-4 provider-home-statistics" }, { children: _jsx("div", __assign({ className: "card" }, { children: _jsxs("div", __assign({ className: "card-body" }, { children: [_jsx("h3", __assign({ style: { fontSize: "1rem" } }, { children: "Estat\u00EDsticas de produtos por tokens ativos" })), _jsx("div", __assign({ className: "card-graphic-pie-subscribers" }, { children: loadPieTokens ? _jsx(CircularProgress, {}) :
                                            emptyChart ?
                                                _jsx("div", __assign({ className: "container-empty-chart" }, { children: _jsx("div", __assign({ className: "box-empty-chart" }, { children: _jsx("h2", __assign({ id: "pie-tokens-title" }, { children: "0" })) })) })) :
                                                _jsxs(_Fragment, { children: [_jsx(Pie, { series: pieTokens.map(function (item) { return item.value; }), labels: pieTokens.map(function (item) { return item.id; }), color: colorsDataPieTokens }), _jsx("div", __assign({ className: "d-flex gap-3", style: { paddingLeft: "1rem" } }, { children: percentagesPieTokens }))] }) }))] })) })) }))] })), _jsx("div", __assign({ className: "row justify-content-between" }, { children: _jsx("div", __assign({ className: "col" }, { children: _jsx("div", __assign({ className: "card" }, { children: _jsxs("div", __assign({ className: "card-body provider-home-current-profit-margin" }, { children: [_jsx("p", { children: "Margem de lucro por assinantes (Globoplay)" }), _jsx("div", __assign({ style: { marginTop: "4rem" } }, { children: _jsxs("div", __assign({ className: "d-flex" }, { children: [_jsx("div", __assign({ className: "box-tokens" }, { children: _jsxs("div", __assign({ className: "d-flex" }, { children: [_jsx("div", __assign({ style: { paddingTop: "0.5rem", } }, { children: _jsx("h3", { children: activeTokensGlobo }) })), _jsxs("div", __assign({ style: { paddingTop: "0.5rem", } }, { children: [_jsx("span", __assign({ className: "span-progress" }, { children: "Por Assinante" })), " ", _jsx("span", __assign({ className: "span-progress" }, { children: "Com Globoplay" }))] }))] })) })), _jsxs("div", __assign({ className: "progress progress-home" }, { children: [_jsx("span", { className: "line-progress-bar-first" }), _jsx("span", { className: "second-line-progress-bar ".concat(activeTokensGlobo >= 250
                                                            ? "progress-line-detach"
                                                            : "", " ").concat(activeTokensGlobo === 500
                                                            ? "progress-line-500"
                                                            : "") }), _jsx("span", { className: "third-line-progress-bar ".concat(activeTokensGlobo >= 500
                                                            ? "progress-line-detach"
                                                            : "") }), _jsx("div", { className: "progress-bar", role: "progressbar", style: {
                                                            width: calculateWidth(activeTokensGlobo),
                                                        }, "aria-valuenow": activeTokensGlobo, "aria-valuemin": 1, "aria-valuemax": 501 })] }))] })) }))] })) })) })) }))] })));
};
export default HomeClient;
