var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal } from 'react-bootstrap';
function ConfirmAction(_a) {
    var show = _a.show, closeModal = _a.closeModal, actionType = _a.actionType, onConfirm = _a.onConfirm;
    var actionTitles = {
        ativar: 'Ativação',
        suspender: 'Suspensão',
        cancelar: 'Cancelar',
        reativar: 'Reativação',
        reenviar: 'Reenviar e-mail de ativação'
    };
    var actionTexts = {
        ativar: 'ativação',
        suspender: 'suspensão',
        cancelar: 'cancelamento',
        reativar: 'reativação',
        reenviar: 'reenviar o e-mail de ativação'
    };
    var title = actionType === 'reenviar'
        ? actionTitles['reenviar']
        : "Confirmar ".concat(actionTitles[actionType] || actionType.charAt(0).toUpperCase() + actionType.slice(1));
    var bodyText = actionType === 'reenviar'
        ? "Você tem certeza que deseja reenviar o e-mail de ativação?"
        : "Voc\u00EA tem certeza que deseja ".concat(actionType, " esse servi\u00E7o? Ao confirmar, voc\u00EA enviar\u00E1 um e-mail de ").concat(actionTexts[actionType] || actionType, " para os endere\u00E7os cadastrados pelos assinantes.");
    return (_jsxs(Modal, __assign({}, { show: show, onHide: closeModal }, { "aria-labelledby": "contained-modal-title-vcenter", centered: true, className: "default-modal-styling" }, { children: [_jsx(Modal.Header, __assign({ closeButton: true }, { children: _jsx("h3", { children: title }) })), _jsx(Modal.Body, __assign({ style: { paddingBottom: "0" } }, { children: _jsx("p", { children: bodyText }) })), _jsxs(Modal.Footer, { children: [_jsx("button", __assign({ onClick: closeModal, className: "button-with-blue-border", style: { width: "106px", height: "36px" } }, { children: "Cancelar" })), _jsx("button", __assign({ onClick: function () { onConfirm(); closeModal(); }, className: "blue-button", style: { width: "106px", height: "36px" } }, { children: "Confirmar" }))] })] })));
}
export default ConfirmAction;
