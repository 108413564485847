var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import MaskedInput from "react-text-mask";
function PhoneMaskInput(props) {
    var _a, _b;
    function getInputMaskPhone(phoneNumber) {
        return [
            "(", /\d/, /\d/, ")",
            /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/
        ];
    }
    return (_jsx(_Fragment, { children: _jsxs("div", { children: [_jsx("label", __assign({ htmlFor: "userEmail", style: props.style }, { children: (_a = props.labelText) !== null && _a !== void 0 ? _a : "Telefone" })), _jsx("div", { children: _jsx(MaskedInput, { className: (_b = props.className) !== null && _b !== void 0 ? _b : "input-password", id: "userTelefone", name: "userTelefone", mask: getInputMaskPhone(props.userphone || ""), defaultValue: props.userphone || "", onChange: props.onChange, type: "tel", showMask: true, required: true, pipe: function (value) {
                            var conformedValue = value.replace(/\D/g, "");
                            var formattedValue = "";
                            if (conformedValue.startsWith("0800")) {
                                formattedValue = "0800 ".concat(conformedValue.slice(4, 6), " ").concat(conformedValue.slice(6));
                            }
                            else if (conformedValue.length >= 2 && conformedValue.length <= 7) {
                                formattedValue = "(".concat(conformedValue.slice(0, 2), ") ").concat(conformedValue.slice(2, 7));
                            }
                            else if (conformedValue.length >= 8) {
                                formattedValue = "(".concat(conformedValue.slice(0, 2), ") ").concat(conformedValue.slice(2, 7), "-").concat(conformedValue.slice(7));
                            }
                            else if (conformedValue.length === 11) {
                                formattedValue = "(".concat(conformedValue.slice(0, 2), ") ").concat(conformedValue.slice(2, 7), "-").concat(conformedValue.slice(7));
                            }
                            else {
                                formattedValue = "(".concat(conformedValue.slice(0, 2), ") ").concat(conformedValue.slice(2, 7), "-").concat(conformedValue.slice(7));
                            }
                            return formattedValue;
                        } }) })] }) }));
}
export default PhoneMaskInput;
