import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import MemberHandbookWelcome from "../components/MemberHandbookWelcome";
import PromotionalMaterials from "../components/PromotionalMaterials";
import "../styles/MemberHandbook.css";
import BenefitsOfTheMemberManual from "../components/BenefitsOfTheMemberManual";
import CreationAndApprovalOfArts from "../components/CreationAndApprovalOfArts";
import CustomerSuccessTeam from "../components/CustomerSuccessTeam";
function MemberHandbook() {
    return (_jsx(_Fragment, { children: _jsxs("div", { children: [_jsx(MemberHandbookWelcome, {}), _jsx(PromotionalMaterials, {}), _jsx(CreationAndApprovalOfArts, {}), _jsx(BenefitsOfTheMemberManual, {}), _jsx(CustomerSuccessTeam, {})] }) }));
}
export default MemberHandbook;
