import { useState } from 'react';
import CustomerService from '../../Customer/services/CustomerService';
import Notification from '../components/Notification';
export function useActiveProducts() {
    var _a = useState([]), products = _a[0], setProducts = _a[1];
    var _b = useState([]), activeProducts = _b[0], setActiveProducts = _b[1];
    var getActiveProducts = function (customerId) {
        CustomerService.getActiveProducts(customerId)
            .then(function (res) {
            var result = res.data.products;
            setProducts(result);
            setActiveProducts(result.filter(function (product) { return product.active; }));
            Notification().Loading.remove();
        })
            .catch(function (err) {
            Notification().Notify.failure(err.message);
        })
            .finally(function () {
            Notification().Loading.remove();
        });
    };
    return { products: products, activeProducts: activeProducts, getActiveProducts: getActiveProducts };
}
