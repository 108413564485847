var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SubscriberService from "../../Subscriber/services/SubscriberService";
import ContentSupplierService from "../services/ContentSupplierService";
import SubscriberForm from "../components/SubscriberForm";
import ServicesForm from "../components/ServicesForm";
import ConfirmForm from "../components/ConfirmForm";
import Title from "../../App/components/Title";
import Breadcrumbs from "../../App/components/Breadcrumbs";
import Notification from "../../App/components/Notification";
import "react-phone-number-input/style.css";
function NewSubscriber() {
    var navigate = useNavigate();
    var _a = useState({
        id: 0,
        name: "",
        email: "",
        document: "",
        phone: "",
        internet_speed_id: 0,
    }), subscriberData = _a[0], setSubscriberData = _a[1];
    var _b = useState([]), allServices = _b[0], setAllServices = _b[1];
    var _c = useState([]), servicesAction = _c[0], setServicesAction = _c[1];
    var _d = useState(), internetSpeedData = _d[0], setInternetSpeedData = _d[1];
    var _e = useState([]), contentSuppliers = _e[0], setContentSuppliers = _e[1];
    var _f = useState(false), modalConfirmForm = _f[0], setModalConfirmForm = _f[1];
    function getAllContentGetSuppliersWithProductsSuppliers() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, ContentSupplierService.getSuppliersWithProducts().then(function (res) {
                            setContentSuppliers(res.data.content_suppliers);
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    ;
    function submit() {
        return __awaiter(this, void 0, void 0, function () {
            var document, storeResponse, servicesResponse, errorMessage, hasError, newSubscriberData, _loop_1, _i, servicesAction_1, serviceAction;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        document = "";
                        storeResponse = "";
                        servicesResponse = "";
                        errorMessage = "";
                        hasError = false;
                        newSubscriberData = {
                            name: subscriberData.name,
                            email: subscriberData.email,
                            document: subscriberData.document,
                            phone: subscriberData.phone,
                            internet_speed_id: subscriberData.internet_speed_id,
                            customer_plan: subscriberData.customer_plan,
                        };
                        Notification().Loading.dots("Carregando");
                        return [4 /*yield*/, SubscriberService.store(newSubscriberData).then(function (res) {
                                storeResponse = "\u2022 ".concat(res.data.response);
                                document = res.data.subscriber.document;
                            }, function (err) {
                                hasError = true;
                                if (err.response.status === 400) {
                                    errorMessage = err.response.data.error;
                                }
                                else if (err.response.status === 403) {
                                    errorMessage = "Usuário não tem permissão para executar essa ação!";
                                }
                            })];
                    case 1:
                        _a.sent();
                        if (!hasError) return [3 /*break*/, 2];
                        Notification().Loading.remove();
                        Notification().Notify.failure(errorMessage);
                        return [3 /*break*/, 7];
                    case 2:
                        _loop_1 = function (serviceAction) {
                            var message, request;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        message = "";
                                        request = {
                                            contentSupplierProduct: serviceAction.content_supplier_product_id,
                                            documents: [document],
                                        };
                                        if (!(serviceAction.action === "activate")) return [3 /*break*/, 2];
                                        return [4 /*yield*/, SubscriberService.provision(request).then(function (res) {
                                                message = res.data.response.success;
                                            }, function (err) {
                                                if (err.response.status === 422) {
                                                    message = err.response.data.response.error;
                                                }
                                                else if (err.response.status === 409) {
                                                    message = err.response.data.response.error;
                                                }
                                            })];
                                    case 1:
                                        _b.sent();
                                        _b.label = 2;
                                    case 2:
                                        servicesResponse += "\u2022 ".concat(message, "<br>");
                                        return [2 /*return*/];
                                }
                            });
                        };
                        _i = 0, servicesAction_1 = servicesAction;
                        _a.label = 3;
                    case 3:
                        if (!(_i < servicesAction_1.length)) return [3 /*break*/, 6];
                        serviceAction = servicesAction_1[_i];
                        return [5 /*yield**/, _loop_1(serviceAction)];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        _i++;
                        return [3 /*break*/, 3];
                    case 6:
                        ;
                        Notification().Loading.remove();
                        Notification().Report.info("Assinante ".concat(subscriberData.name.split(" ")[0]), "".concat(storeResponse, " </br> ").concat(servicesResponse), "Confirmar");
                        navigate("/subscribers");
                        _a.label = 7;
                    case 7: return [2 /*return*/];
                }
            });
        });
    }
    ;
    var handleDataCallback = function (data) {
        setSubscriberData(data);
    };
    var handleServicesDataUpdate = function (data) {
        setAllServices(data);
    };
    var openModal = function () {
        setModalConfirmForm(true);
    };
    var closeModal = function () {
        setModalConfirmForm(false);
    };
    var breadcrumbsItens = [
        {
            name: "Gerenciar assinantes",
            route: "/subscribers"
        },
        {
            name: "Cadastrar novo assinante",
            route: "/subscribers/new"
        }
    ];
    useEffect(function () {
        getAllContentGetSuppliersWithProductsSuppliers();
    }, []);
    return (_jsxs("div", __assign({ className: "content-internal-padding" }, { children: [_jsx(Title, { text: "Cadastrar novo assinante" }), _jsx(Breadcrumbs, { itens: breadcrumbsItens }), _jsxs("div", __assign({ className: "container-subscriber" }, { children: [_jsx(SubscriberForm, { subscriberData: subscriberData, callbackSubscriberData: setSubscriberData, callbackInternetSpeedData: setInternetSpeedData, callbackSubscriberDataUpdate: handleDataCallback }), _jsx(ServicesForm, { allServicesData: allServices, servicesAction: servicesAction, callbackAllServices: setAllServices, callbackServicesAction: setServicesAction, subscriberData: subscriberData, contentSuppliersData: contentSuppliers, callbackServicesUpdate: handleServicesDataUpdate }), _jsx(ConfirmForm, { allServices: allServices, subscriberData: subscriberData, internetSpeedData: internetSpeedData, callbackAction: submit, show: modalConfirmForm, closeModal: closeModal }), _jsxs("div", __assign({ style: { display: "flex", justifyContent: "flex-end", paddingRight: "2rem", marginTop: "2rem" } }, { children: [_jsx("button", __assign({ className: "button-cancel-update-form button-with-blue-border" }, { children: "Cancelar" })), _jsx("button", __assign({ className: "button-update-form blue-button", onClick: openModal }, { children: "Confirmar" }))] }))] }))] })));
}
export default NewSubscriber;
