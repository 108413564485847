var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '@mui/icons-material/Close';
import "../styles/ModalConfirmImportSubscribers.css";
function ModalConfirmImportSubscribers(props) {
    return (_jsx(_Fragment, { children: _jsxs(Modal, __assign({}, props, { "aria-labelledby": "contained-modal-title-vcenter", centered: true, className: "modal-confirm-import" }, { children: [_jsxs(Modal.Header, { children: [_jsx("h3", { children: "Aviso" }), _jsx(CloseIcon, { onClick: props.closeModal })] }), _jsxs(Modal.Body, { children: [_jsx("div", { children: _jsx("p", { children: "Para evitar erros durante a importa\u00E7\u00E3o, preencha os campos da forma correta, conforme o exemplo abaixo. Campos marcados com * s\u00E3o obrigat\u00F3rios." }) }), _jsx("div", { children: _jsxs("table", { children: [_jsx("tr", { children: props.selectService === "Méliuz" ? (_jsx("th", { children: "*CPF" })) : (_jsxs(_Fragment, { children: [_jsx("th", { children: "*Nome" }), _jsx("th", { children: "*Email" }), _jsx("th", { children: "*CPF" }), _jsx("th", { children: "*Telefone" }), _jsx("th", { children: "*Velocidade" }), _jsx("th", { children: "Produto" }), _jsx("th", { children: "Plano" })] })) }), _jsx("tr", { children: props.selectService === "Méliuz" ? (_jsx("td", { children: "15544965510" })) : (_jsxs(_Fragment, { children: [_jsx("td", { children: "Nome Sobrenome" }), _jsx("td", { children: "user@mail.com.br" }), _jsx("td", { children: "15544965510" }), _jsx("td", { children: "+5521988888888" }), _jsx("td", { children: "100mbps" }), _jsx("td", { children: "globoplay_canais" }), _jsx("td", { children: "Plano Globoplay 100mbps" })] })) })] }) })] }), _jsx(Modal.Footer, { children: _jsx("button", __assign({ className: "blue-button", type: "submit", onClick: props.callbackSendFile }, { children: "Continuar" })) })] })) }));
}
;
export default ModalConfirmImportSubscribers;
