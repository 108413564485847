var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { sendMailToRecoveryPassword } from "../../App/services/auth";
import { EMAIL_REGEX } from "../../App/utils/formatter";
import Notification from "../../App/components/Notification";
import ispDefaulter from "../../App/assets/isp-defaulter.png";
import SmallLogoHeader from "../components/SmallLogoHeader";
import "../styles/SendMailChangePassword.css";
function SendMailChangePassword() {
    var _this = this;
    var _a = useForm({ mode: "onChange" }), register = _a.register, handleSubmit = _a.handleSubmit, _b = _a.formState, errors = _b.errors, isValid = _b.isValid, isDirty = _b.isDirty, isSubmitting = _b.isSubmitting;
    var navigate = useNavigate();
    var submit = function (data) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    Notification().Loading.dots("Enviando e-mail");
                    return [4 /*yield*/, sendMailToRecoveryPassword(data).then(function (res) {
                            Notification().Loading.remove();
                            Notification().Notify.success(res.data.success);
                            Notification().Confirm.show("E-mail enviado", "Um link de redefinição de senha foi enviado para a caixa de entrada do seu email.", "Prosseguir", "Enviar novamente", function () {
                                navigate("/");
                                handleSubmit(submit);
                            });
                        }, function (err) {
                            Notification().Loading.remove();
                            if (err.response.status === 422 || err.response.status === 400 || err.response.status === 500) {
                                Notification().Notify.failure(err.response.data.error);
                            }
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsx(SmallLogoHeader, {}), _jsxs("div", __assign({ className: "container-send-email-change-password" }, { children: [_jsx("img", { src: ispDefaulter, alt: "" }), _jsx("h2", { children: "Redefinir senha" }), _jsxs("p", { children: ["Insira seu endere\u00E7o de email e enviaremos um link para que voc\u00EA possa redefinir sua senha.", _jsx("br", {})] }), _jsx("div", __assign({ className: "form-send-email-change-password" }, { children: _jsxs("form", __assign({ onSubmit: handleSubmit(submit) }, { children: [_jsxs("div", __assign({ className: "mb-4" }, { children: [_jsxs("span", { children: [_jsx("p", __assign({ className: "text-email" }, { children: "E-mail:" })), _jsx("input", __assign({ required: true, type: "email", className: "form-control input-send-email-change-password", id: "email" }, register("email", { pattern: { value: EMAIL_REGEX, message: 'Por favor, insira um e-mail válido' } })))] }), errors.email && _jsx("small", { children: errors.email.message })] })), _jsx("button", __assign({ className: "btn-super w-100 button-submit", type: "submit", disabled: !isValid || !isDirty || isSubmitting }, { children: "Enviar" }))] })) }))] }))] }));
}
export default SendMailChangePassword;
