var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "../styles/CustomerSuccessTeam.css";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import anaSarahCS from "../../App/assets/cs-ana-sarah.png";
import annaCardosoCS from "../../App/assets/annacardoso.png";
import vanessaMartinsCS from "../../App/assets/vanessa-martins.png";
import janneQueirozCS from "../../App/assets/janne-queiroz.png";
import tailaCristinaCS from "../../App/assets/taila-cristina.png";
import beatrizMattosCS from "../../App/assets/cs-beatriz.png";
import caioXavierCS from "../../App/assets/cs-caio.png";
import calbyMedeirosCS from "../../App/assets/calby-medeiros.png";
import jessicaNevesCS from "../../App/assets/jessica_neves.png";
import raphaelTenanCS from "../../App/assets/raphael_tenan.png";
import yasmimMonteiroCS from "../../App/assets/yasmim_monteiro_.png";
function CustomerSuccessTeam() {
    var customersSuccess = [
        {
            photo: janneQueirozCS,
            nameCS: "Janne Queiroz",
            position: "Supervisora de Sucesso do Cliente",
            telephone: "(55) 21 97502-2127",
            email: "janne.queiroz@celeti.com.br"
        },
        {
            photo: anaSarahCS,
            nameCS: "Ana Sarah",
            position: "Sucesso do Cliente",
            telephone: "(55) 21 99401-7363",
            email: "ana.lins@celetihub.com.br"
        },
        {
            photo: annaCardosoCS,
            nameCS: "Anna Cardoso",
            position: "Sucesso do Cliente",
            telephone: "(55) 21 99992-8892",
            email: "anna.cardoso@celeti.com.br"
        },
        {
            photo: vanessaMartinsCS,
            nameCS: "Vanessa Martins",
            position: "Sucesso do Cliente",
            telephone: "(55) 21 97502-9839",
            email: "vanessa.martins@celetihub.com.br"
        },
        {
            photo: calbyMedeirosCS,
            nameCS: "Calby Medeiros",
            position: "Sucesso do Cliente",
            telephone: "(55) 21 99862-4261",
            email: "calby.medeiros@celetihub.com.br"
        },
        {
            photo: beatrizMattosCS,
            nameCS: "Beatriz Mattos",
            position: "Sucesso do Cliente",
            telephone: "(55) 21 97502-3632",
            email: "beatriz.mattos@celetihub.com.br"
        },
        {
            photo: tailaCristinaCS,
            nameCS: "Taila Anunciação",
            position: "Sucesso do Cliente",
            telephone: "(55) 21 97493-6295",
            email: "taila.anunciacao@celetihub.com.br"
        },
        {
            photo: caioXavierCS,
            nameCS: "Caio Xavier",
            position: "Sucesso do Cliente",
            telephone: "(55) 21 4020-1697",
            email: "caio.xavier@celetihub.com.br"
        },
        {
            photo: jessicaNevesCS,
            nameCS: "Jessica Neves",
            position: "Sucesso do Cliente",
            telephone: "(55) 21 99191-5735",
            email: "jessica.neves@celetihub.com.br"
        },
        {
            photo: yasmimMonteiroCS,
            nameCS: "Yasmim Monteiro",
            position: "Sucesso do Cliente",
            telephone: "(55) 21 99384-4778",
            email: "yasmim.monteiro@celetihub.com.br"
        },
        {
            photo: raphaelTenanCS,
            nameCS: "Raphael Tenan",
            position: "Sucesso do Cliente",
            telephone: "(55) 21 96648-9262",
            email: "raphael.tenan@celetihub.com.br"
        },
    ];
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "container-customer-success-team", id: "customer-success-team" }, { children: [_jsx("h2", __assign({ className: "title-customer-success-team" }, { children: "Nosso Time de Customer Success" })), _jsx("p", __assign({ className: "text-customer-success-team" }, { children: "Sempre pronto para te atender" })), _jsxs("div", __assign({ className: "box-information-contact-customers" }, { children: [_jsx("div", __assign({ className: "contact-customers" }, { children: customersSuccess.map(function (item) { return (_jsxs("div", __assign({ className: "contact-customers-item" }, { children: [_jsx("img", { src: item.photo, alt: "Imagem de perfil do Customer Success" }), _jsxs("div", { children: [_jsx("p", { children: item.nameCS }), _jsx("span", __assign({ className: "text-end" }, { children: item.position })), _jsxs("div", __assign({ className: "customer-item-contact" }, { children: [item.telephone &&
                                                        _jsx("p", { children: item.telephone }), _jsx("p", { children: item.email })] }))] })] }))); }) })), _jsxs("div", __assign({ className: "box-contact-member-handbook" }, { children: [_jsx("p", { children: "Siga-nos nas redes sociais e principais canais:" }), _jsxs("div", { children: [_jsx("a", __assign({ href: "https://www.facebook.com/celetihub", target: "_blank" }, { children: _jsx(FacebookIcon, { sx: { fontSize: "3rem" } }) })), _jsx("a", __assign({ href: "https://www.instagram.com/celetihub/", target: "_blank" }, { children: _jsx(InstagramIcon, { sx: { fontSize: "3rem" } }) })), _jsx("a", __assign({ href: "https://www.youtube.com/c/Celetioficial", target: "_blank" }, { children: _jsx(YouTubeIcon, { sx: { fontSize: "3rem" } }) })), _jsx("a", __assign({ href: "https://www.linkedin.com/company/celeti-tecnologia/", target: "_blank" }, { children: _jsx(LinkedInIcon, { sx: { fontSize: "3rem" } }) }))] })] }))] }))] })) }));
}
export default CustomerSuccessTeam;
