var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Can, CanAny } from "../utils/Authorize";
import useAuth from "../hooks/useAuth";
import BoxImpersonate from "./BoxImpersonate";
import celetihubLogo2 from "../assets/celetihub-logo2.png";
import "../styles/Header.css";
import "../styles/Header.css";
import Dropdown from 'react-bootstrap/Dropdown';
import Avatar from '@mui/material/Avatar';
import { deepOrange } from '@mui/material/colors';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
function Header(props) {
    var _this = this;
    var _a = useState(false), showDropdownMenu = _a[0], setShowDropdownMenu = _a[1];
    var unAuthenticate = useAuth().unAuthenticate;
    var _b = useAuth(), auth = _b.auth, setAuth = _b.setAuth;
    var navigate = useNavigate();
    var clearUserDataInLocalStorage = function () {
        localStorage.removeItem("userData");
        navigate("/");
    };
    var handleLogout = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    clearUserDataInLocalStorage();
                    return [4 /*yield*/, unAuthenticate()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var toggleDropdown = function () {
        setShowDropdownMenu(!showDropdownMenu);
    };
    return (_jsx(_Fragment, { children: _jsxs("header", __assign({ className: "header-menu", style: { top: props.isDevEnvironment ? '24px' : '0' } }, { children: [_jsxs("div", __assign({ className: "container-logo" }, { children: [props.sideBarIsOpen ? (_jsx(CloseOutlinedIcon, { className: "material-symbols-rounded header-menu-icon", onClick: props.handleHamburguerIcon })) : (_jsx(MenuOutlinedIcon, { className: "material-symbols-rounded header-menu-icon", onClick: props.handleHamburguerIcon })), _jsx("div", __assign({ className: "header-logo" }, { children: _jsx("img", { alt: "Logo CeletiHUB", src: celetihubLogo2, width: 100, onClick: function () { return navigate("/"); } }) }))] })), _jsx("div", { children: _jsxs(Dropdown, { children: [_jsx(Dropdown.Toggle, { children: _jsx(Avatar, __assign({ sx: { bgcolor: deepOrange[400] } }, { children: auth.name[0] })) }), _jsxs(Dropdown.Menu, { children: [!auth.impersonate &&
                                        _jsx("div", __assign({ className: "dropdown-container-item", onClick: toggleDropdown }, { children: _jsx("p", __assign({ style: { paddingBottom: '1rem' } }, { children: auth.name })) })), _jsxs(CanAny, __assign({ permissions: ["viewPage-userProfile", "viewAny-customerEmployee"] }, { children: [_jsx("hr", {}), _jsxs("div", __assign({ className: "dropdown-container-item" }, { children: [_jsx(Can, __assign({ permission: "viewPage-userProfile" }, { children: _jsxs(Dropdown.Item, __assign({ href: "/profile" }, { children: [_jsx(DescriptionOutlinedIcon, { className: "material-symbols-rounded  dropdown-container-item-icon" }), _jsx("p", __assign({ className: "dropdown-container-item-text" }, { children: "Dados" }))] })) })), _jsx(Can, __assign({ permission: "viewAny-customerEmployee" }, { children: _jsxs(Dropdown.Item, __assign({ href: "/management_provider" }, { children: [_jsx(AccountCircleOutlinedIcon, { className: "material-symbols-rounded dropdown-container-item-icon" }), _jsx("p", __assign({ className: "dropdown-container-item-text" }, { children: "Usu\u00E1rios" }))] })) }))] }))] })), _jsx("hr", {}), !auth.impersonate &&
                                        _jsx("div", __assign({ className: "header-menu-profile-logout dropdown-container-item" }, { children: _jsxs(Dropdown.Item, __assign({ onClick: handleLogout }, { children: [_jsx(LogoutOutlinedIcon, { className: "material-symbols-rounded header-menu-icon-profile-logout dropdown-container-item-icon" }), _jsx("p", { children: "Sair" })] })) }))] })] }) }), _jsx(BoxImpersonate, {})] })) }));
}
export default Header;
