var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import BillingService from "../services/BillingService";
import { Can } from "../../App/utils/Authorize";
import Title from "../../App/components/Title";
import Notification from "../../App/components/Notification";
import Breadcrumbs from "../../App/components/Breadcrumbs";
import ModalInvoice from "../components/ModalInvoice";
import "../styles/BillingDetail.css";
import { Pagination } from '@mui/material';
import { Tooltip } from "@mui/material";
import ContentSuppliersTabs from "../../App/components/ContentSuppliersTabs";
import useContentSuppliers from "../../App/hooks/useContentSuppliers";
import { handleServiceError } from "../../App/components/ErrorHandler";
import { documentFormatter, formatCurrencyValues } from "../../App/utils/formatter";
import SubscriberService from "../../Subscriber/services/SubscriberService";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import InfoIcon from '@mui/icons-material/Info';
import CircularProgress from '@mui/material/CircularProgress';
function BillingDetail() {
    var _this = this;
    var _a = useState([]), billingResume = _a[0], setBillingResume = _a[1];
    var _b = useState([]), billingResumeGlobo = _b[0], setBillingResumeGlobo = _b[1];
    var _c = useState(), billingResumeInfo = _c[0], setBillingResumeInfo = _c[1];
    var _d = useState(null), billingStatusId = _d[0], setBillingStatusId = _d[1];
    var _e = useState(0), downPaymentId = _e[0], setDownPaymentId = _e[1];
    var _f = useState(null), orderId = _f[0], setOrderId = _f[1];
    var _g = useState(), billingValue = _g[0], setBillingValue = _g[1];
    var _h = useState([]), billingResumeMeliuz = _h[0], setBillingResumeMeliuz = _h[1];
    var _j = useState(undefined), selectedSupplierId = _j[0], setSelectedSupplierId = _j[1];
    var _k = useState(undefined), selectService = _k[0], setSelectService = _k[1];
    var _l = useState('#'), nfse_link = _l[0], setNfseLink = _l[1];
    var _m = useState('#'), xml_link = _m[0], setXmlLink = _m[1];
    var _o = useState(true), isOpen = _o[0], setIsOpen = _o[1];
    var menuRef = useRef(null);
    var _p = useState(false), showModalInvoice = _p[0], setShowModalInvoice = _p[1];
    var _q = useState(1), currentPage = _q[0], setCurrentPage = _q[1];
    var _r = useState(0), totalItems = _r[0], setTotalItems = _r[1];
    var _s = useState(20), perPage = _s[0], setPerPage = _s[1];
    var _t = useState(), paginateData = _t[0], setPaginateData = _t[1];
    var _u = useState(true), loading = _u[0], setLoading = _u[1];
    var _v = useContentSuppliers(), contentSuppliers = _v.contentSuppliers, getAllContentGetSuppliersWithProducts = _v.getAllContentGetSuppliersWithProducts;
    var hasFetchedRef = useRef(false);
    var billing_id = useParams().billing_id;
    var navigate = useNavigate();
    var location = useLocation();
    var prod_url = process.env.REACT_APP_STORAGE_URL;
    useEffect(function () {
        if (!hasFetchedRef.current) {
            getAllContentGetSuppliersWithProducts();
            hasFetchedRef.current = true;
        }
    }, [getAllContentGetSuppliersWithProducts, contentSuppliers]);
    useEffect(function () {
        var selectedService = localStorage.getItem('selectedService');
        var serviceId = selectedService ? parseInt(selectedService) : undefined;
        if (!selectedSupplierId && !selectService && contentSuppliers.length > 0) {
            setSelectedSupplierId(serviceId || contentSuppliers[0].id);
            setSelectService(serviceId || contentSuppliers[0].id);
        }
    }, [selectedSupplierId, selectService, contentSuppliers]);
    useEffect(function () {
        if (selectedSupplierId !== undefined) {
            if (selectedSupplierId === 1) {
                setBillingResume(billingResumeGlobo);
            }
            else if (selectedSupplierId === 2) {
                setBillingResume(billingResumeMeliuz);
            }
        }
    }, [selectedSupplierId, billingResumeGlobo, billingResumeMeliuz]);
    useEffect(function () {
        var _a;
        if ((_a = location.state) === null || _a === void 0 ? void 0 : _a.billingStatusId) {
            setBillingStatusId(location.state.billingStatusId);
        }
    }, [location.state]);
    useEffect(function () {
        var _a;
        if ((_a = location.state) === null || _a === void 0 ? void 0 : _a.downPaymentId) {
            setDownPaymentId(location.state.downPaymentId);
        }
    }, [location.state]);
    useEffect(function () {
        var _a;
        if ((_a = location.state) === null || _a === void 0 ? void 0 : _a.orderId) {
            setOrderId(location.state.orderId);
        }
    }, [location.state]);
    useEffect(function () {
        if (billing_id) {
            getBillingResume(parseInt(billing_id));
        }
        getAllMeliuz(currentPage, perPage);
    }, []);
    useEffect(function () {
        if (billingResumeInfo) {
            var calculatedTotalWithMargin = total(billingResumeInfo);
            setBillingValue(calculatedTotalWithMargin);
        }
    }, [billingResumeInfo]);
    function getBillingResume(billingId, page, rows) {
        if (page === void 0) { page = 1; }
        if (rows === void 0) { rows = 20; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                Notification().Block.circle("[data-id=billing-grid]");
                BillingService.find(billingId, page, rows).then(function (res) {
                    var _a, _b, _c, _d, _e;
                    var result = res.data.billings_resume.data;
                    setBillingResumeGlobo(result);
                    setBillingResumeInfo(res.data.billings_resume.info);
                    setPaginateData(res.data.billings_resume);
                    setTotalItems(res.data.billings_resume.total);
                    setLoading(false);
                    if (((_a = res.data.billings_resume.info) === null || _a === void 0 ? void 0 : _a.nfse_link.indexOf('focusnfe')) == -1) {
                        setNfseLink(prod_url + ((_b = res.data.billings_resume.info) === null || _b === void 0 ? void 0 : _b.nfse_link));
                        setXmlLink(prod_url + ((_c = res.data.billings_resume.info) === null || _c === void 0 ? void 0 : _c.xml_link));
                    }
                    else {
                        setNfseLink((_d = res.data.billings_resume.info) === null || _d === void 0 ? void 0 : _d.nfse_link);
                        setXmlLink((_e = res.data.billings_resume.info) === null || _e === void 0 ? void 0 : _e.xml_link);
                    }
                }, function (err) {
                    if (err.response.status === 403) {
                        Notification().Notify.failure("Usuário não tem permissão para visualizar essa página!");
                    }
                    else {
                        Notification().Notify.failure(err.message);
                    }
                });
                Notification().Block.remove("[data-id=billing-grid]");
                return [2 /*return*/];
            });
        });
    }
    ;
    function getAllMeliuz(page, perPage, orderBy) {
        if (page === void 0) { page = 1; }
        if (perPage === void 0) { perPage = 20; }
        if (orderBy === void 0) { orderBy = []; }
        return __awaiter(this, void 0, void 0, function () {
            var res, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        Notification().Block.circle("[data-id=billing-grid]");
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, SubscriberService.getAllDocumentsByCustomerId(page, perPage, orderBy)];
                    case 2:
                        res = _a.sent();
                        setBillingResumeMeliuz(res.data.data);
                        setTotalItems(res.data.total);
                        Notification().Block.remove("[data-id=billing-grid]");
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _a.sent();
                        handleServiceError(error_1);
                        return [3 /*break*/, 5];
                    case 4:
                        Notification().Block.remove("[data-id=billing-grid]");
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    }
    ;
    function getBankSlip(id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        Notification().Block.circle("[data-id=payment-links]");
                        return [4 /*yield*/, BillingService.getBankSlip(id).then(function (res) {
                                Notification().Block.remove("[data-id=payment-links]");
                                var file = new Blob([res.data]);
                                var url = window.URL.createObjectURL(file);
                                var tempLink = document.createElement("a");
                                tempLink.href = url;
                                tempLink.setAttribute("target", "_blank");
                                tempLink.setAttribute("download", "boleto.pdf");
                                document.body.appendChild(tempLink);
                                tempLink.click();
                                document.body.removeChild(tempLink);
                                window.URL.revokeObjectURL(res.data.bank_slip);
                            }, function (err) {
                                Notification().Block.remove("[data-id=payment-links]");
                                if (err.response.status === 422 || err.response.status === 500) {
                                    var data = JSON.parse(new TextDecoder().decode(err.response.data));
                                    Notification().Notify.failure(data.error);
                                }
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    ;
    function generateBillingSummary(id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        Notification().Block.circle("[data-id=billing-grid]");
                        return [4 /*yield*/, BillingService.generateBillingSummary(id).then(function (res) {
                                Notification().Block.remove("[data-id=billing-grid]");
                                var file = new Blob([res.data]);
                                var url = window.URL.createObjectURL(file);
                                var tempLink = document.createElement("a");
                                tempLink.href = url;
                                tempLink.setAttribute("target", "_blank");
                                tempLink.setAttribute("download", "fatura.pdf");
                                document.body.appendChild(tempLink);
                                tempLink.click();
                                document.body.removeChild(tempLink);
                                window.URL.revokeObjectURL(res.data.summary);
                            }, function (err) {
                                Notification().Block.remove("[data-id=billing-grid]");
                                if (err.response.status === 422 || err.response.status === 500) {
                                    var data = JSON.parse(new TextDecoder().decode(err.response.data));
                                    Notification().Notify.failure(data.error);
                                }
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    ;
    function handleExport(id) {
        return __awaiter(this, void 0, void 0, function () {
            var response, blob, downloadUrl, a, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        Notification().Block.circle("[data-id=billing-links]");
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, BillingService.exportAll(id, { responseType: 'blob' })];
                    case 2:
                        response = _a.sent();
                        blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                        downloadUrl = window.URL.createObjectURL(blob);
                        a = document.createElement("a");
                        a.href = downloadUrl;
                        a.download = "faturas-mensal.xlsx";
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        Notification().Block.remove("[data-id=billing-links]");
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        if (err_1.response && err_1.response.status === 403) {
                            Notification().Notify.failure("Usuário não tem permissão para executar essa ação!");
                        }
                        else {
                            Notification().Notify.failure("Erro ao exportar arquivo: " + (err_1.message));
                        }
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }
    ;
    var toggleMenu = function (event) {
        event.stopPropagation();
        setIsOpen(function (prev) { return !prev; });
    };
    var handleClickOutside = function (event) {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };
    useEffect(function () {
        document.addEventListener('click', handleClickOutside);
        return function () {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);
    function total(billingResumeInfo) {
        if (billingResumeInfo) {
            var plan = billingResumeInfo === null || billingResumeInfo === void 0 ? void 0 : billingResumeInfo.total_plan;
            var totalTokens = billingResumeInfo === null || billingResumeInfo === void 0 ? void 0 : billingResumeInfo.total;
            var extra = billingResumeInfo === null || billingResumeInfo === void 0 ? void 0 : billingResumeInfo.extra;
            var total_1 = totalTokens + plan + extra;
            return total_1;
        }
    }
    ;
    var handlePageChange = function (event, value) {
        if (value > Math.ceil(totalItems / perPage)) {
            return;
        }
        setCurrentPage(value);
        getBillingResume(Number(billing_id), value, perPage);
    };
    var handleSupplierChange = function (supplierId) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setSelectService(supplierId);
            setSelectedSupplierId(supplierId);
            Notification().Block.circle("[data-id=billing-grid]");
            localStorage.setItem('selectedService', supplierId.toString());
            if (supplierId) {
                try {
                    Notification().Block.remove("[data-id=billing-grid]");
                }
                catch (error) {
                    handleServiceError(error);
                }
            }
            else {
                setSelectService(undefined);
            }
            return [2 /*return*/];
        });
    }); };
    var supplierColumns = {
        1: [
            { label: "Nome", key: "subscriber_name" },
            { label: "E-mail", key: "subscriber_email" },
            { label: "Telefone", key: "subscriber_phone" },
            { label: "Valor", key: "total" },
        ],
        2: [
            { label: "CPF", key: "document" },
            { label: "E-mail", key: "email" },
        ],
    };
    var columns = supplierColumns[selectedSupplierId] || [];
    function billingGroupData() {
        var total = (billingResumeInfo === null || billingResumeInfo === void 0 ? void 0 : billingResumeInfo.total_plan) || 0;
        return (_jsxs("div", __assign({ style: { fontSize: "0.7rem" } }, { children: [_jsxs("div", __assign({ className: "d-flex align-items-center justify-content-between" }, { children: [_jsx("p", { children: "Mensalidade CHUB: " }), _jsx("p", { children: formatCurrencyValues(total) })] })), billingResumeInfo === null || billingResumeInfo === void 0 ? void 0 : billingResumeInfo.resume.map(function (item, index) {
                    var _a = item.discountValue, discountValue = _a === void 0 ? 0 : _a, _b = item.tokens, tokens = _b === void 0 ? 0 : _b, _c = item.minimum, minimum = _c === void 0 ? 0 : _c, _d = item.contentSupplierProductGroup, contentSupplierProductGroup = _d === void 0 ? "" : _d, _e = item.activeTokens, activeTokens = _e === void 0 ? 0 : _e, _f = item.activeTokensLabel, activeTokensLabel = _f === void 0 ? "" : _f;
                    total += tokens + minimum - discountValue;
                    return (_jsxs("div", { children: [tokens > 0 && (_jsxs("div", __assign({ className: "d-flex align-items-center justify-content-between" }, { children: [_jsxs("p", { children: [contentSupplierProductGroup, " (", activeTokens, " ", activeTokensLabel, "): "] }), _jsxs("p", { children: ["+ ", formatCurrencyValues(tokens)] })] }))), minimum > 0 && (_jsxs("div", __assign({ className: "d-flex align-items-center justify-content-between" }, { children: [_jsx("p", { children: "M\u00EDnimo: " }), _jsxs("p", { children: ["+ ", formatCurrencyValues(minimum)] })] }))), discountValue > 0 && (_jsxs("div", __assign({ className: "d-flex align-items-center justify-content-between" }, { children: [_jsx("p", { children: "Desconto: " }), _jsxs("p", { children: ["- ", formatCurrencyValues(discountValue)] })] })))] }, index));
                }), total !== 0 && (_jsxs("div", __assign({ className: "d-flex align-items-center justify-content-between" }, { children: [_jsx("p", { children: "Total: " }), _jsx("p", { children: formatCurrencyValues(total) })] })))] })));
    }
    var breadcrumbsItens = [
        {
            name: "Minhas faturas",
            route: "/billings"
        },
        {
            name: "Detalhes da fatura",
            route: "/billings/".concat(billing_id)
        }
    ];
    return (_jsxs("div", __assign({ className: "content-internal-padding" }, { children: [_jsx(Title, { text: "Detalhes da fatura", variableText: location.state.referenceDate, style: { color: 'gray' }, hr: false }), _jsx(Breadcrumbs, { itens: breadcrumbsItens }), _jsxs("div", __assign({ className: "container-billing-detail", "data-id": "billing-grid" }, { children: [loading ? (_jsx("div", __assign({ style: { display: "flex", alignItems: "center", justifyContent: "center", padding: "1rem" }, className: "card-total-invoice" }, { children: _jsx(CircularProgress, {}) }))) : (_jsxs("div", __assign({ className: "container-total-invoice", "data-id": "billing-links" }, { children: [_jsxs("div", __assign({ className: "billing-buttons" }, { children: [_jsxs("div", { children: [_jsx("button", __assign({ className: "blue-button", style: { width: "83px", height: "42px" }, onClick: function () { return getBankSlip(Number(billing_id)); } }, { children: "Pagar" })), _jsx("button", __assign({ className: "button-with-blue-border", style: { width: "135px", height: "42px" }, onClick: function () { return generateBillingSummary(Number(billing_id)); } }, { children: "Baixar fatura" })), _jsx("button", __assign({ className: "button-with-blue-border", style: { width: "122px", height: "42px" }, onClick: function () { return setShowModalInvoice(true); } }, { children: "Nota fiscal" }))] }), _jsx("div", { children: _jsx("button", __assign({ onClick: function () { return handleExport(Number(billing_id)); }, className: "btn-report-export button-with-blue-border", style: { height: "42px" } }, { children: "Relat\u00F3rio" })) })] })), _jsxs("div", __assign({ className: "card-total-invoice" }, { children: [_jsxs("div", __assign({ className: "pl-4 card-total" }, { children: [_jsx("p", __assign({ className: "title-billing-detail" }, { children: "Total \u00E0 pagar" })), _jsxs("p", __assign({ id: "total-payable" }, { children: ["R$", " ", billingValue !== undefined &&
                                                        billingValue.toLocaleString('pt-br', { minimumFractionDigits: 2 }), _jsx(Tooltip, __assign({ title: billingGroupData() }, { children: _jsx(InfoIcon, { style: { cursor: "pointer", fontSize: "1rem", marginBottom: "2rem", color: "#EC691B" } }) }))] })), _jsx("button", __assign({ className: "dropdown-button", onClick: toggleMenu }, { children: isOpen ? _jsx(KeyboardArrowUpIcon, {}) : _jsx(ExpandMoreIcon, {}) }))] })), _jsxs("div", __assign({ className: "container-billing-resume ".concat(isOpen ? 'open' : '') }, { children: [_jsxs("div", __assign({ className: "container-billing-item" }, { children: [_jsx("div", __assign({ style: { width: "52%" } }, { children: _jsxs(_Fragment, { children: [_jsx("span", __assign({ style: { fontWeight: "bold" } }, { children: "Mensalidade CHUB:" })), _jsxs("span", { children: ["R$ ", billingResumeInfo === null || billingResumeInfo === void 0 ? void 0 : billingResumeInfo.total_plan.toLocaleString('pt-br', { minimumFractionDigits: 2 })] })] }) })), (billingResumeInfo === null || billingResumeInfo === void 0 ? void 0 : billingResumeInfo.extra) !== 0 &&
                                                        _jsx("div", __assign({ style: { width: "52%" } }, { children: _jsxs(_Fragment, { children: [_jsx("span", __assign({ style: { fontWeight: "bold" } }, { children: "Extra:" })), _jsxs("span", { children: ["R$ ", billingResumeInfo === null || billingResumeInfo === void 0 ? void 0 : billingResumeInfo.extra.toLocaleString('pt-br', { minimumFractionDigits: 2 })] })] }) }))] })), (billingResumeInfo === null || billingResumeInfo === void 0 ? void 0 : billingResumeInfo.billing_group_data.length) === 0 || (billingResumeInfo === null || billingResumeInfo === void 0 ? void 0 : billingResumeInfo.billing_group_data[0].total) === 0 ? (_jsx("div", __assign({ className: "d-flex align-items-center justify-content-center p-4" }, { children: _jsx("strong", { children: "PARA MAIS DETALHES, BAIXE A FATURA" }) }))) : (billingResumeInfo === null || billingResumeInfo === void 0 ? void 0 : billingResumeInfo.resume.map(function (item, index) {
                                                var discountValue = item.discountValue;
                                                var tokens = item.tokens;
                                                var minimum = item.minimum;
                                                return (_jsxs(_Fragment, { children: [(isNaN(tokens) || tokens === null || tokens === 0) && (item.activeTokens === 0 || item.activeTokens === null) ? (_jsx(_Fragment, {})) : ((isNaN(tokens) || tokens === null || tokens === 0) && (item.activeTokens !== 0 || item.activeTokens !== null) ? (_jsx("div", __assign({ className: "container-billing-item" }, { children: _jsx("div", __assign({ style: { width: "52%" } }, { children: _jsxs(_Fragment, { children: [_jsxs("span", __assign({ style: { fontWeight: "bold" } }, { children: [item.contentSupplierProductGroup, ": ", item.activeTokens, " ", item.activeTokensLabel] })), _jsx("span", { children: "-" })] }) })) }), index)) : ((!isNaN(tokens) || tokens !== null || tokens !== 0) && (item.activeTokens !== 0 || item.activeTokens !== null) &&
                                                            _jsx("div", __assign({ className: "container-billing-item" }, { children: _jsx("div", __assign({ style: { width: "52%" } }, { children: _jsxs(_Fragment, { children: [_jsxs("span", __assign({ style: { fontWeight: "bold" } }, { children: [item.contentSupplierProductGroup, ": ", item.activeTokens, " ", item.activeTokensLabel] })), _jsx("span", { children: formatCurrencyValues(tokens) })] }) })) }), index))), (isNaN(discountValue) || discountValue === 0 || discountValue === null) ? (_jsx(_Fragment, {})) : (_jsx("div", __assign({ className: "container-billing-item" }, { children: _jsx("div", __assign({ style: { width: "52%" } }, { children: _jsxs(_Fragment, { children: [_jsx("span", __assign({ style: { fontWeight: "bold" } }, { children: "Desconto: " })), _jsxs("span", { children: ["-", formatCurrencyValues(discountValue)] })] }) })) }))), (isNaN(minimum) || minimum === 0 || minimum === null) ? (_jsx(_Fragment, {})) : (_jsx("div", __assign({ className: "container-billing-item" }, { children: _jsx("div", __assign({ style: { width: "52%" } }, { children: _jsxs(_Fragment, { children: [_jsx("span", __assign({ style: { fontWeight: "bold" } }, { children: "M\u00EDnimo:" })), _jsx("span", { children: formatCurrencyValues(minimum) })] }) })) }), index))] }));
                                            }))] }))] }))] }))), _jsx(ContentSuppliersTabs, { contentSuppliers: contentSuppliers, onSupplierChange: handleSupplierChange, selectedSupplierId: selectedSupplierId }), _jsx(_Fragment, { children: _jsxs("table", __assign({ className: "default-table-styling", style: { marginTop: "0" }, "data-id": "billing-grid" }, { children: [_jsx("thead", { children: _jsx("tr", { children: columns.map(function (column) { return (_jsx("th", { children: column.label }, column.key)); }) }) }), _jsx("tbody", { children: billingResume.length > 0 && selectedSupplierId !== undefined ? (billingResume.map(function (billingResumeData, key) { return (_jsx(Can, __assign({ permission: "viewPage-tokenBillingReport" }, { children: _jsx(Tooltip, __assign({ title: "Clique para visualizar o Relat\u00F3rio de Cobran\u00E7a do assinante ".concat(billingResumeData.subscriber_name), placement: "top", arrow: true }, { children: _jsx("tr", __assign({ onClick: selectedSupplierId === 1 ? function () { return navigate("/billings/".concat(billingResumeData.token_id, "/report"), { state: { billingDetails: billingResumeData } }); } : undefined, style: { cursor: selectedSupplierId === 1 ? "pointer" : "default" } }, { children: columns.map(function (column) { return (_jsx("td", { children: column.key === "total"
                                                        ? new Intl.NumberFormat("pt-BR", {
                                                            style: "currency",
                                                            currency: "BRL",
                                                        }).format(billingResumeData.total !== null ?
                                                            (billingResumeData.need_override === 1 ? -billingResumeData.total : billingResumeData.total) :
                                                            billingResumeData.billing_details_total * (1 - billingResumeData.margin / 100))
                                                        : column.key === "document"
                                                            ? billingResumeData[column.key] ? documentFormatter(billingResumeData[column.key]) : ''
                                                            : column.key === "subscriber_phone"
                                                                ? formatPhoneNumberIntl(billingResumeData[column.key])
                                                                : billingResumeData[column.key] }, column.key)); }) })) })) }), key)); })) : (_jsx("tr", { children: _jsx("td", __assign({ className: "text-center", colSpan: columns.length }, { children: "Nenhum dado a ser exibido" })) })) })] })) })] })), _jsx(ModalInvoice, { show: showModalInvoice, closeModal: function () { return setShowModalInvoice(false); }, nfse_link: nfse_link, xml_link: xml_link }), paginateData !== undefined && (_jsx("div", __assign({ className: "d-flex justify-content-end mt-4" }, { children: _jsx("div", { children: _jsx(Pagination, { count: Math.ceil(totalItems / perPage), page: currentPage, onChange: handlePageChange, color: "standard", sx: {
                            '& .MuiPaginationItem-root': {
                                color: '#ff8926',
                            },
                            '& .MuiPaginationItem-root.Mui-selected': {
                                backgroundColor: '#ff8926',
                                color: '#fff',
                            },
                            '& .MuiPaginationItem-root:hover': {
                                backgroundColor: '#ff8926',
                                color: '#fff',
                            }
                        }, showFirstButton: true, showLastButton: true }) }) })))] })));
}
export default BillingDetail;
