import { api } from "../../App/services/api";
function allProducts(contentSupplierId) {
    return api.get("/content_suppliers/".concat(contentSupplierId, "/products"));
}
function allContent() {
    return api.get("/content_suppliers");
}
function getSuppliersWithProducts() {
    return api.get("/content_suppliers/get_suppliers_with_products");
}
function getActiveContentSupplierProducts(subscriberId, contentSupplierId) {
    var uri = "/products?";
    if (subscriberId !== undefined) {
        uri += "subscriber_id=".concat(subscriberId, "&");
    }
    if (contentSupplierId !== undefined) {
        uri += "content_supplier_id=".concat(contentSupplierId, "&");
    }
    uri = uri.endsWith('&') ? uri.slice(0, -1) : uri;
    return api.get(uri);
}
export default {
    allProducts: allProducts,
    allContent: allContent,
    getActiveContentSupplierProducts: getActiveContentSupplierProducts,
    getSuppliersWithProducts: getSuppliersWithProducts
};
