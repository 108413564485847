var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";
import UsersService from "../services/UsersService";
import Notification from "../../App/components/Notification";
import { phoneNumberFormatter2 } from "../../App/utils/formatter";
import "../../Customer/styles/change-password.css";
import Title from "../../App/components/Title";
import { Can } from "../../App/utils/Authorize";
function ManagementProvider() {
    var navigate = useNavigate();
    var _a = useState([]), usersFromAdmin = _a[0], setUsersFromAdmin = _a[1];
    function newAdmin() {
        navigate("/new_management");
    }
    useEffect(function () {
        getUsersFromAdmin();
    }, []);
    function changeData(user) {
        var userPermissions = [];
        user.permissions.forEach(function (permission) {
            userPermissions.push(permission.id);
        });
        var editUser = __assign(__assign({}, user), { permissions: userPermissions });
        navigate("/edit_management/".concat(editUser.id, "/"), {
            state: {
                user: editUser,
            },
        });
    }
    function getUsersFromAdmin() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                UsersService.all().then(function (res) {
                    var result = res.data.users.data;
                    setUsersFromAdmin(result);
                }, function (err) {
                    if (err.response.status === 403) {
                        Notification().Notify.failure("Usuário não tem permissão para visualizar essa página!");
                    }
                    else {
                        Notification().Notify.failure(err.message);
                    }
                });
                return [2 /*return*/];
            });
        });
    }
    function showDeleteConfirmation(id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                Notification().Confirm.show("Excluir usuário", "Tem certeza de que deseja excluir o usuário?", "Excluir", "Cancelar", function () {
                    handleDelete(id);
                });
                return [2 /*return*/];
            });
        });
    }
    function handleDelete(id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                Notification().Loading.dots("Carregando");
                UsersService.destroy(id).then(function (res) {
                    Notification().Loading.remove();
                    Notification().Notify.success(res.data.response);
                    setUsersFromAdmin(function (deletedvUsers) { return deletedvUsers.filter(function (user) { return user.id !== id; }); });
                }, function (err) {
                    Notification().Loading.remove();
                    if (err.response.status === 403) {
                        Notification().Notify.failure("Usuário não tem permissão para executar essa ação!");
                    }
                    else {
                        Notification().Notify.failure(err.response.data.response);
                    }
                });
                return [2 /*return*/];
            });
        });
    }
    return (_jsxs("div", __assign({ className: "content-internal-padding" }, { children: [_jsx(Title, { text: "Dados dos Usu\u00E1rios" }), _jsx(Can, __assign({ permission: "create-customerEmployee" }, { children: _jsx("div", __assign({ className: "row" }, { children: _jsx("div", __assign({ className: "col" }, { children: _jsx("div", __assign({ className: "card mb-4" }, { children: _jsxs("div", __assign({ className: "card-body d-flex", style: { paddingBottom: "0.5rem" } }, { children: [_jsx("span", __assign({ style: { cursor: "pointer" }, onClick: function () { return newAdmin(); } }, { children: _jsx(AddCircleOutlineIcon, { className: "addUserIcon" }) })), _jsx("p", __assign({ style: { marginLeft: "4px", paddingTop: "9px" } }, { children: "Novo" }))] })) })) })) })) })), _jsx("div", __assign({ className: "row" }, { children: _jsx("div", __assign({ className: "col" }, { children: _jsx("div", __assign({ className: "card" }, { children: _jsx("div", __assign({ className: "table-padding" }, { children: _jsx("div", __assign({ className: "table-responsive table-overflow" }, { children: _jsxs("table", __assign({ className: "table table-borderless" }, { children: [_jsx("thead", { children: _jsxs("tr", __assign({ className: "table-header" }, { children: [_jsx("th", { children: "Nome" }), _jsx("th", { children: "Telefone" }), _jsx("th", { children: "E-mail" })] })) }), _jsxs("tbody", __assign({ className: "td-line" }, { children: [usersFromAdmin.length > 0 ?
                                                    (usersFromAdmin.map(function (data, key) { return (_jsxs("tr", { children: [_jsx("td", __assign({ style: { padding: "1rem" } }, { children: data.name })), _jsx("td", __assign({ style: { padding: "1rem" } }, { children: phoneNumberFormatter2(data.telephone) })), _jsx("td", __assign({ style: { padding: "1rem" } }, { children: data.email })), _jsx(Can, __assign({ permission: "update-customerEmployee" }, { children: _jsx("th", __assign({ style: { padding: "1rem" } }, { children: _jsx(Tooltip, __assign({ title: "Editar", placement: "top", arrow: true }, { children: _jsx(CreateIcon, { onClick: function () { return changeData(data); }, style: { cursor: "pointer", color: "#003981" } }) })) })) })), _jsx(Can, __assign({ permission: "delete-customerEmployee" }, { children: _jsx("th", __assign({ style: { padding: "1rem" } }, { children: _jsx(Tooltip, __assign({ title: "Excluir", placement: "top", arrow: true }, { children: _jsx(DeleteIcon, { style: { color: "#003981", cursor: "pointer" }, onClick: function () { return showDeleteConfirmation(data.id); } }) })) })) }))] }, key)); })) : (_jsx("tr", { children: _jsx("td", __assign({ className: "text-center", colSpan: 6, style: { padding: "1rem" } }, { children: "Nenhum usu\u00E1rio cadastrado" })) })), _jsx("tr", {})] }))] })) })) })) })) })) }))] })));
}
export default ManagementProvider;
