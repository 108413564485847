var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import DrangAndDropReview from './DrangAndDropReview';
import "./../styles/AprovalArts.css";
import "./../styles/ModalReviewOfArt.css";
import ArtsService from '../services/ArtsService';
import Notification from "../../App/components/Notification";
function ModalReviewOfTheArt(props) {
    var _a = useState([]), messages = _a[0], setMessages = _a[1];
    var _b = useState([]), checkedMessages = _b[0], setCheckedMessages = _b[1];
    var _c = useState(''), justification = _c[0], setJustification = _c[1];
    var _d = useState(undefined), file = _d[0], setFile = _d[1];
    useEffect(function () {
        setCheckedMessages([]);
        setJustification('');
        setFile(undefined);
        getArtDisapprovalArts();
    }, [props.artToReview]);
    function getArtDisapprovalArts() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, ArtsService.getArtDisapprovalOptions()
                            .then(function (res) {
                            setMessages(res.data.message);
                        }, function (error) {
                            Notification().Notify.failure(error.response.data.error);
                            Notification().Block.remove("[data-id=arts-grid]");
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    ;
    function handleCheckChange(e) {
        var _a = e.target, checked = _a.checked, id = _a.id;
        setCheckedMessages(function (prevMessages) {
            if (checked) {
                return __spreadArray(__spreadArray([], prevMessages, true), [Number(id)], false);
            }
            else {
                return prevMessages.filter(function (messageId) { return messageId !== Number(id); });
            }
        });
    }
    ;
    function handleJustificationChange(e) {
        setJustification(e.target.value);
    }
    ;
    function handleFileEmail(file) {
        setFile(file);
    }
    ;
    function sendEmailDisapproved() {
        return __awaiter(this, void 0, void 0, function () {
            var formData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        formData = new FormData();
                        formData.append('checkedMessages', JSON.stringify(checkedMessages));
                        formData.append('justification', justification);
                        if (props.artToReview.id !== undefined) {
                            formData.append('art_id', props.artToReview.id.toString());
                        }
                        if (file !== undefined) {
                            formData.append('file', file);
                        }
                        return [4 /*yield*/, ArtsService.sendEmailDisapproved(formData)
                                .then(function (res) {
                                Notification().Block.circle("[data-id=arts-grid]");
                                Notification().Notify.success("E-mail enviado com sucesso!");
                                props.updateArts().then(function () {
                                    Notification().Block.remove("[data-id=arts-grid]");
                                });
                            }, function (error) {
                                Notification().Notify.failure(error.response.data.error);
                                Notification().Block.remove("[data-id=arts-grid]");
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    ;
    return (props.artToReview ? (_createElement(Modal, __assign({}, props, { key: props.artToReview.art_id, "aria-labelledby": "contained-modal-title-vcenter", centered: true, className: "modal-review-of-art" }),
        _jsx(Modal.Header, __assign({ className: "title-modal arts" }, { children: _jsxs(Modal.Title, __assign({ className: "d-flex flex-column align-items-center justify-content-center" }, { children: [_jsxs("h4", { children: ["Avaliar Conte\u00FAdo de ", props.artToReview.user_uploaded_art] }), _jsx("div", __assign({ className: "evaluate-content" }, { children: _jsxs("span", { children: ["Arte: ", _jsx("strong", { children: props.artToReview.art_path })] }) }))] })) })),
        _jsxs(Modal.Body, __assign({ className: "box-modal-body" }, { children: [_jsxs("div", __assign({ className: "box-categories" }, { children: [_jsx("p", { children: "Motivos para a reprova\u00E7\u00E3o" }), messages.map(function (item) { return (_jsxs("div", { children: [_jsx("input", { type: "checkbox", id: item.id.toString(), name: item.label, onChange: handleCheckChange }), _jsx("label", __assign({ style: { marginLeft: "0.5rem;" }, htmlFor: item.id.toString() }, { children: item.label }))] }, item.id)); }), _jsx(DrangAndDropReview, { pageName: "aprovalArts", handleFileEmail: handleFileEmail })] })), _jsxs("div", __assign({ className: "container-justification-area" }, { children: [_jsx("p", { children: "Justificativa" }), _jsx("div", __assign({ className: "justification-area" }, { children: _jsx("textarea", { className: "form-control", id: "exampleFormControlTextarea1", rows: 4, onChange: handleJustificationChange }) }))] }))] })),
        _jsxs(Modal.Footer, __assign({ className: "modal-footer", style: { marginTop: "-1rem" } }, { children: [_jsx("button", __assign({ onClick: function () {
                        if (checkedMessages.length === 0 && justification.trim() === '' && file === undefined) {
                            Notification().Notify.failure("Preencha os campos");
                        }
                        else {
                            sendEmailDisapproved();
                            props.closeModal();
                        }
                    }, className: "close-button" }, { children: "Enviar" })), _jsx("button", __assign({ onClick: props.closeModal, className: "button-to-cancel-action", style: { width: "10rem" } }, { children: "Fechar" }))] })))) : (null));
}
export default ModalReviewOfTheArt;
