var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SubscriberService from "../services/SubscriberService";
import { Can, CanAny } from "../../App/utils/Authorize";
import { documentFormatter } from "../../App/utils/formatter";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import SubscriberActivityReport from "./SubscriberActivityReport";
import Notification from "../../App/components/Notification";
import "../styles/SubscribersList.css";
import "../../App/styles/GlobalStyle.css";
import FilterAltOffRoundedIcon from "@mui/icons-material/FilterAltOffRounded";
import { Tooltip } from "@mui/material";
import { PriorityHigh } from "@mui/icons-material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
var SubscribersList = function (props) {
    var _a;
    var navigate = useNavigate();
    var _b = useState(false), modalShow = _b[0], setModalShow = _b[1];
    var _c = useState([]), subscriberHistory = _c[0], setSubscriberHistory = _c[1];
    var _d = useState(''), subscriberNameReport = _d[0], setSubscriberNameReport = _d[1];
    var _e = useState(null), expandedRow = _e[0], setExpandedRow = _e[1];
    var handleExpandRow = function (id) {
        setExpandedRow(expandedRow === id ? null : id);
    };
    function editSubscriber(subscriberData, contentSuppliersProducts, tab, contentSupplierId) {
        navigate("/subscribers/".concat(subscriberData.id, "/update"), {
            state: {
                subscriber: {
                    id: subscriberData.id,
                    name: subscriberData.name,
                    email: subscriberData.email,
                    document: subscriberData.document,
                    phone: subscriberData.phone,
                    internet_speed_id: subscriberData.internet_speed_id,
                    customer_plan: subscriberData.customer_plan,
                    customer_id: subscriberData.customer_id,
                    services: subscriberData.services,
                },
                services: subscriberData.services,
                contentSupplierId: contentSupplierId,
                contentSuppliers: props.contentSuppliers,
            },
        });
    }
    ;
    function getSubscriberHistory(subscriber, service) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                Notification().Loading.dots("Buscando Dados");
                SubscriberService.getSubscriberHistory(subscriber.id)
                    .then(function (res) {
                    if (service) {
                        var filteredHistories = res.data.histories.data.filter(function (history) {
                            return history.service === service.content_supplier_product_name;
                        });
                        setSubscriberHistory(filteredHistories);
                    }
                    else {
                        setSubscriberHistory(res.data.histories.data);
                    }
                    setSubscriberNameReport(subscriber.name);
                    setModalShow(true);
                })
                    .catch(function (error) {
                    Notification().Loading.remove();
                    if (error.response && error.response.status === 403) {
                        Notification().Notify.failure("Usuário não tem permissão para executar essa ação!");
                    }
                    else {
                        Notification().Notify.failure(error.message);
                    }
                })
                    .finally(function () {
                    Notification().Loading.remove();
                });
                return [2 /*return*/];
            });
        });
    }
    ;
    return (_jsxs("div", { children: [_jsxs("table", __assign({ className: "default-table-styling" }, { children: [_jsxs("tr", { children: [_jsx("th", {}), _jsx("th", { children: "Nome" }), _jsx("th", { children: "E-mail" }), _jsx("th", __assign({ className: props.serviceValue !== 0 ? "column-filtered" : "", style: { marginBottom: props.serviceValue !== 0 ? "1.4rem" : "16px" } }, { children: _jsxs("div", __assign({ style: { display: "flex", alignItems: "center", justifyContent: "center" } }, { children: [_jsxs("select", __assign({ className: props.serviceValue === 0 ? "default-table-styling-select default-service-select" : "default-table-styling-select filtered-service-select", onChange: function (e) { return props.callbackFilterService(e); } }, { children: [_jsx("option", __assign({ hidden: true, value: 0, selected: props.serviceValue === 0, className: "select-option" }, { children: "Servi\u00E7os" })), props.contentSupplierProductsList != undefined ? (props.contentSupplierProductsList.length > 0 ? ((_a = props.contentSupplierProductsList) === null || _a === void 0 ? void 0 : _a.map(function (item, index) { return (_jsx("option", __assign({ value: item.id, selected: props.serviceValue === item.id, className: "select-option" }, { children: item.name }), index)); })) : (_jsx("option", __assign({ disabled: true }, { children: "Nenhum cadastrado" })))) : ("")] })), props.qtyErrors !== 0 &&
                                            _jsxs("span", __assign({ onClick: function () { return props.callbackFilterError(); }, className: "qty-errors" }, { children: [_jsx("b", { children: props.qtyErrors }), " ERROS"] }))] })) })), _jsx("th", { children: "CPF" }), _jsx("th", { children: "Telefone" }), _jsx("th", { children: _jsx(Tooltip, __assign({ title: "Limpar filtros", placement: "top", arrow: true }, { children: _jsx("button", __assign({ onClick: function () { return props.callbackFilterClear(); }, type: "button", className: "button-filter-clear" }, { children: _jsx(FilterAltOffRoundedIcon, {}) })) })) })] }), props.subscribersList.length > 0 ? (props.subscribersList.map(function (subscriber) {
                        var isExpanded = expandedRow === subscriber.id;
                        return (_jsx(CanAny, __assign({ permissions: ["update-subscriber", "delete-subscriber", "view-subscriberActivityReport"] }, { children: _jsxs("tr", __assign({ onClick: function (event) {
                                    if (!event.target.closest('.product-badge') && !event.target.closest('.arrowdown-icon') && !event.target.closest('.arrowup-icon') && !event.target.closest('.input-check-subscriber') && !event.target.closest('.subscriber-checkbox')) {
                                        event.stopPropagation();
                                        editSubscriber(subscriber, props.contentSupplierProductsList, "updateSubscriber", props.contentSupplierId);
                                    }
                                }, style: { cursor: "pointer" } }, { children: [_jsx("td", __assign({ className: "subscriber-checkbox" }, { children: _jsxs("div", __assign({ className: "container-input-check-subscriber" }, { children: [_jsx("input", { type: "checkbox", checked: props.activeRadio == subscriber.document, name: "subscriber", value: subscriber.document, onChange: function () { return props.callbackCheckboxSubscriber(subscriber.document); }, className: "input-check-subscriber" }), _jsx("p", { className: "mb-0 subscriber-id", onClick: function () { return editSubscriber(subscriber, props.contentSupplierProductsList, "updateSubscriber", props.contentSupplierId); } })] })) })), _jsx(Tooltip, __assign({ title: subscriber.name, placement: "top", arrow: true }, { children: _jsx("td", __assign({ style: { maxWidth: "250px" } }, { children: subscriber.name })) })), _jsx(Tooltip, __assign({ title: subscriber.email, placement: "top", arrow: true }, { children: _jsx("td", __assign({ style: { maxWidth: "250px" } }, { children: subscriber.email })) })), _jsx("td", __assign({ style: props.serviceValue === 0 ? {} : { backgroundColor: "rgba(234, 90, 28, .1)", maxWidth: "200px" }, className: "container-services-subscriber", onClick: function (event) { event.stopPropagation(); getSubscriberHistory(subscriber); } }, { children: _jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "flex-pattern" }, { children: _jsxs(Can, __assign({ permission: "view-subscriberActivityReport" }, { children: [_jsx(Tooltip, __assign({ title: "Clique para ver o relatório de atividades", placement: "top", arrow: true }, { children: _jsx("div", __assign({ className: "product-badge" }, { children: subscriber.services.filter(function (service) { return service.status_id === 1 || service.status_id === 5; }).length })) })), subscriber.services.map(function (item) {
                                                                return (item.has_error === 1 ? (_jsx(Tooltip, __assign({ title: "Clique para expandir" }, { children: _jsx("button", __assign({ className: "button-has-error", onClick: function (event) { event.stopPropagation(); handleExpandRow(subscriber.id); } }, { children: _jsx(PriorityHigh, { className: "".concat(isExpanded ? 'expanded' : '') }) })) }))) : null);
                                                            })] })) })), isExpanded && (subscriber.services.length === 0
                                                    ? _jsx("p", __assign({ style: { marginTop: "0.5rem" } }, { children: "Nenhum servi\u00E7o" }))
                                                    : (function () {
                                                        // const lastServices: { [key: number]: IServiceData } = {};
                                                        // subscriber.services.forEach((item: IServiceData) => {lastServices[item.group_id] = item;})
                                                        // return Object.values(lastServices).map((item, key) => {
                                                        return Object.values(subscriber.services).map(function (item, key) {
                                                            return (_jsx(Tooltip, __assign({ title: "Clique para ver o relat\u00F3rio de atividades do servi\u00E7o ".concat(item.content_supplier_product_name), placement: "top", arrow: true }, { children: _jsxs("div", __assign({ style: { display: "flex", marginTop: "5px", justifyContent: "center" }, onClick: function (event) {
                                                                        event.stopPropagation();
                                                                        getSubscriberHistory(subscriber, item);
                                                                    } }, { children: [_jsxs("div", __assign({ className: item.has_error ? "service-block service_with_error" : "service-block " }, { children: [_jsx("div", __assign({ className: "service-name-status", style: { backgroundColor: item === null || item === void 0 ? void 0 : item.icon_color } }, { children: item.token_status_translation })), _jsx("span", __assign({ className: "service-name", style: { whiteSpace: "normal", wordBreak: "break-all" } }, { children: item.content_supplier_product_name }))] }), key), item.has_error === 1 ?
                                                                            _jsx("div", __assign({ className: "fix-error-action-button button", onClick: function (event) {
                                                                                    event.stopPropagation();
                                                                                    props.callbackFixErrorAction(subscriber.document, item.content_supplier_product_id, item.status_id);
                                                                                } }, { children: _jsx(PriorityHigh, { className: "error-icon" }) }))
                                                                            : null] })) })));
                                                        });
                                                    })())] }) })), _jsx("td", { children: documentFormatter(subscriber.document) }), _jsx("td", { children: formatPhoneNumberIntl(subscriber.phone) }), _jsx("td", { children: _jsx(Tooltip, __assign({ title: isExpanded ? "Clique para recolher as informações" : "Clique para expandir e visualizar mais informações", placement: "top", arrow: true }, { children: isExpanded ? (_jsx(KeyboardArrowUpIcon, { className: "arrowup-icon ".concat(isExpanded ? 'expanded' : ''), "data-testid": "arrow-up", onClick: function (event) { event.stopPropagation(); handleExpandRow(subscriber.id); } })) : (_jsx(KeyboardArrowDownIcon, { className: "arrowdown-icon ".concat(isExpanded ? 'expanded' : ''), "data-testid": "arrow-down", onClick: function (event) { event.stopPropagation(); handleExpandRow(subscriber.id); } })) })) })] }), subscriber.id) })));
                    })) : (_jsx(_Fragment, { children: _jsx("tr", { children: _jsx("td", __assign({ colSpan: 8 }, { children: props.searchingTerm !== ""
                                    ? "Nenhum assinante encontrado"
                                    : "Nenhum assinante cadastrado" })) }) }))] })), _jsx(SubscriberActivityReport, { show: modalShow, subscriberHistory: subscriberHistory, subscriberNameReport: subscriberNameReport, closeModal: function () { setModalShow(false); } })] }));
};
export default SubscribersList;
