var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Modal from 'react-bootstrap/Modal';
import "../../App/styles/GlobalStyle.css";
import "./../styles/ModalWithArtInformation.css";
function ModalWithArtInformation(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    function formatDate(date) {
        var options = {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric"
        };
        var dateToFormat = new Date(date);
        var dateFormat = dateToFormat.toLocaleDateString("pt-br", options);
        return dateFormat;
    }
    var prod_url = process.env.REACT_APP_STORAGE_URL;
    return (_jsx(_Fragment, { children: _jsxs(Modal, __assign({ "aria-labelledby": "contained-modal-title-vcenter", centered: true }, props, { show: props.showModalArtInfo }, { children: [_jsx(Modal.Header, { children: _jsx(Modal.Title, { children: _jsx("h3", { children: "Arte avaliada" }) }) }), _jsx(Modal.Body, { children: _jsxs("div", __assign({ className: "container-modal-with-art-information" }, { children: [_jsx("h5", { children: "Nome do provedor:" }), _jsx("p", { children: (_a = props.art) === null || _a === void 0 ? void 0 : _a.customer_name }), _jsx("h5", { children: "Data da avalia\u00E7\u00E3o:" }), _jsx("p", { children: ((_b = props.art) === null || _b === void 0 ? void 0 : _b.updated_at) && formatDate((_c = props.art) === null || _c === void 0 ? void 0 : _c.updated_at) }), _jsx("h5", { children: "Status:" }), _jsx("p", { children: ((_d = props.art) === null || _d === void 0 ? void 0 : _d.status_id) === 5 && "Reprovado" }), ((_e = props.art) === null || _e === void 0 ? void 0 : _e.report) &&
                                _jsxs("div", { children: [_jsx("h5", { children: "Justificativa:" }), _jsx("p", { children: (_f = props.art) === null || _f === void 0 ? void 0 : _f.report })] }), ((_g = props.art) === null || _g === void 0 ? void 0 : _g.image_response) !== "/arts_disapproval/" &&
                                _jsxs("div", { children: [_jsx("h5", __assign({ style: { paddingBottom: "0.7rem" } }, { children: "Imagem:" })), _jsx("div", __assign({ className: "box-art-image-response" }, { children: _jsx("img", { src: "".concat(prod_url) + ((_h = props.art) === null || _h === void 0 ? void 0 : _h.image_response), alt: "Imagem da Justificativa" }) }))] }), ((_j = props.art) === null || _j === void 0 ? void 0 : _j.art_disapproval_option_labels) &&
                                _jsxs("div", { children: [_jsx("h5", { children: "Motivos da reprova\u00E7\u00E3o:" }), _jsx("p", { children: (_k = props.art) === null || _k === void 0 ? void 0 : _k.art_disapproval_option_labels.map(function (item) { return _jsx("p", __assign({ className: "art-text-label" }, { children: item.label })); }) })] })] })) }), _jsx(Modal.Footer, { children: _jsx("button", __assign({ onClick: props.closeModal, className: "button-to-cancel-action" }, { children: "Fechar" })) })] })) }));
}
;
export default ModalWithArtInformation;
