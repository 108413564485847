import { api } from "./api";
export function login(credentials) {
    return api.post('/login', credentials);
}
export function logout() {
    return api.post('/logout');
}
export function validate(payload) {
    return api.post('/validate', payload);
}
export function firstAccess(request) {
    return api.post('/first_access', request);
}
export function sendMailToRecoveryPassword(data) {
    return api.post('/password_recovery', data);
}
export function forgotPassword(request) {
    var headers = {
        'Authorization': 'Bearer ' + request.token
    };
    return api.post('/forgot_password', request, { headers: headers });
}
export function getAuthenticatedUserData() {
    return api.get('/authenticated_user_data');
}
