var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReportService from "../../Report/services/ReportService";
import "../../App/styles/HomeAdmin.css";
import Notification from "../../App/components/Notification";
import Title from "../../App/components/Title";
var HomeSeller = function () {
    var navigate = useNavigate();
    var _a = useState(0), totalProvisioned = _a[0], setTotalProvisioned = _a[1];
    var _b = useState([]), providers = _b[0], setProviders = _b[1];
    useEffect(function () {
        getProviders();
    }, []);
    function realodPage(url) {
        if (window.location.pathname === url) {
            navigate(url, { replace: true });
            window.location.reload();
        }
    }
    function handleType(type, value) {
        var number = _jsx("span", { children: value });
        switch (type) {
            case "currency":
                return _jsxs(_Fragment, { children: [" R$ ", number, " "] });
            case "percent":
                return _jsxs(_Fragment, { children: [" ", number, "% "] });
            default:
                return _jsxs(_Fragment, { children: [" ", number, " "] });
        }
    }
    function getProviders() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        Notification().Block.circle("[data-id=providers-report-grid]");
                        return [4 /*yield*/, ReportService.getCustomersData().then(function (res) {
                                setProviders(res.data.customers.data);
                                Notification().Block.remove("[data-id=providers-report-grid]");
                            }, function (err) {
                                Notification().Notify.failure(err.message);
                                Notification().Block.remove("[data-id=providers-report-grid]");
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    return (_jsxs("div", __assign({ className: "cs-home content-internal-padding" }, { children: [_jsx(Title, { text: "Painel Administrativo" }), _jsx("div", __assign({ className: "admin-providers" }, { children: _jsx("div", __assign({ className: "row" }, { children: _jsx("div", __assign({ className: "col" }, { children: _jsx("div", __assign({ className: "card" }, { children: _jsx("div", __assign({ className: "card-body" }, { children: _jsx("div", __assign({ className: "table-responsive" }, { children: _jsxs("table", __assign({ className: "table table-hover align-middle" }, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", __assign({ scope: "col" }, { children: "Provedor" })), _jsx("th", __assign({ scope: "col" }, { children: "Total de assinantes" })), _jsx("th", __assign({ scope: "col" }, { children: "Assinantes ativos" })), _jsx("th", __assign({ scope: "col" }, { children: "Assinantes inativos" }))] }) }), _jsx("tbody", __assign({ "data-id": "providers-grid" }, { children: providers.length > 0 ? (providers.map(function (item, index) { return (_jsxs("tr", { children: [_jsx("td", { children: item.name }), _jsx("td", { children: item.total_subscribers }), _jsxs("td", { children: [item.active_tokens, "/ 500"] }), _jsx("td", { children: item.deactivated_tokens })] }, index)); })) : (_jsx("tr", { children: _jsx("td", __assign({ className: "text-center", colSpan: 9 }, { children: "Nenhum provedor cadastrado." })) })) }))] })) })) })) })) })) })) }))] })));
};
export default HomeSeller;
