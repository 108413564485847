var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ContentSupplierService from "../services/ContentSupplierService";
import { canceledStatusId } from "../../App/helpers/TokenStatusHelper";
import Notification from "../../App/components/Notification";
import "../styles/ServicesForm.css";
import "../../App/styles/GlobalStyle.css";
import InfoIcon from "@mui/icons-material/Info";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { handleServiceError } from "../../App/components/ErrorHandler";
import ServiceStatusOptions from "./ServiceStatusOptions";
import { currentServiceStatus, getStatus } from "../services/ServiceStatus";
import { TokenStatusInformation } from "../../App/helpers/TokenStatusInformation";
function ServicesForm(props) {
    var subscriberId = props.subscriberId, subscriberData = props.subscriberData, contentSuppliersData = props.contentSuppliersData, subscriberEnabledServicesData = props.subscriberEnabledServicesData, allServicesData = props.allServicesData, servicesAction = props.servicesAction, contentSupplierId = props.contentSupplierId, callbackServicesUpdate = props.callbackServicesUpdate, callbackAllServices = props.callbackAllServices, callbackServicesAction = props.callbackServicesAction;
    var _a = useState(allServicesData), allServices = _a[0], setAllServices = _a[1]; //Todos serviços
    var _b = useState(subscriberData), subscriberInformation = _b[0], setSubscriberInformation = _b[1]; //Mostra os dados e os serviços ativos do assinante
    var _c = useState(subscriberInformation), updatedSubscriberInformation = _c[0], setUpdatedSubscriberInformation = _c[1]; //Dados + serviços ativos do assinante atualizados
    var _d = useState({}), selectedActions = _d[0], setSelectedActions = _d[1]; //Pega o status selecionado no select
    var _e = useState(servicesAction), servicesActions = _e[0], setServicesActions = _e[1];
    var location = useLocation();
    function expired(expirationDate) {
        var expDate = new Date(expirationDate);
        var currentDate = new Date();
        return expDate < currentDate;
    }
    useEffect(function () {
        function getContentSuppliersProducts() {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    Notification().Loading.dots("Buscando serviços...");
                    ContentSupplierService.getActiveContentSupplierProducts(subscriberId, contentSupplierId)
                        .then(function (res) {
                        var updatedServices = res.data.content_supplier_products.map(function (product) { return initializeServiceOptions(product); }); //Adicionando informações usando initializeServiceOptions
                        var serviceGroupList = {}; //Lista de grupos de serviços
                        var subscriberEnabledServices = subscriberEnabledServicesData === null || subscriberEnabledServicesData === void 0 ? void 0 : subscriberEnabledServicesData.filter(function (service) { return service.status_id !== 3; }); //Filtrando os serviços ativos do assinante onde o status seja diferente de cancelado
                        //Iterando sobre os serviços atualizados para agrupar por group_id
                        updatedServices.forEach(function (product) {
                            if ((product.service.active === 1) || (product.service.active === 0 && subscriberId !== undefined)) { //Verificando se o produto está ativo ou não e se subscriberId está definido
                                if (!serviceGroupList[product.service.group_id]) { //Se o grupo ainda não existir inicializa um array vazio
                                    serviceGroupList[product.service.group_id] = [];
                                }
                                serviceGroupList[product.service.group_id].push(product); // Adiciona o produto ao grupo correspondente
                            }
                        });
                        //Iterando sobre cada grupo de serviços para adicionar informações
                        Object.keys(serviceGroupList).forEach(function (groupId) {
                            var group = serviceGroupList[groupId]; //Pega o grupo atual baseado no groupId
                            //Iterando sobre cada produto no grupo 
                            group.forEach(function (product) {
                                var tier = 0;
                                var activeSubscriberService = null; //Para guardar o serviço ativo do assinante
                                if (subscriberEnabledServices && subscriberEnabledServices.length > 0) {
                                    for (var i = 0; i < group.length; i++) {
                                        activeSubscriberService = subscriberEnabledServices.find(function (service) {
                                            return service.content_supplier_product_id === product.service.id;
                                        });
                                        if (activeSubscriberService) {
                                            tier = product.service.tier; //Atualizando o tier se um serviço ativo for encontrado
                                            break;
                                        }
                                    }
                                }
                                var result = initializeServiceOptions(product.service, activeSubscriberService, tier); //Adicionando informações ao grupo usando a função initializeServiceOptions
                                setAllServices(function (prevServices) { return __spreadArray(__spreadArray([], prevServices, true), [result], false); });
                            });
                        });
                    })
                        .catch(function (error) {
                        handleServiceError(error);
                    })
                        .finally(function () {
                        Notification().Loading.remove();
                    });
                    return [2 /*return*/];
                });
            });
        }
        getContentSuppliersProducts();
    }, [subscriberId, contentSupplierId]);
    useEffect(function () {
        var updatedServicesData = allServices
            .filter(function (service) { return service.promotion !== 1; })
            .map(function (item) { return ({
            promotion: item.promotion,
            service: item.service,
            options: item.options,
            selected_action: item.selected_action,
            new_status: item.new_status === 0 ? item.service.status_id : item.new_status,
            provisioned: item.provisioned,
            current_status: item.current_status,
            old_service: item.old_service,
            content_supplier_id: item.service.content_supplier_id,
        }); });
        callbackAllServices(updatedServicesData);
    }, [allServices]);
    useEffect(function () {
        if (!allServices || !subscriberInformation.services)
            return;
        var updateTiers = allServices.map(function (service) { return service.service.tier; });
        var serviceIds = allServices.map(function (service) { return service.service.id; });
        var tierMap = new Map();
        serviceIds.forEach(function (id, index) { tierMap.set(id, updateTiers[index]); });
        var updatedServices = subscriberInformation.services.map(function (service) {
            var tier = tierMap.get(service.content_supplier_product_id);
            return __assign(__assign({}, service), { tier: tier !== undefined ? tier : service.tier });
        });
        setUpdatedSubscriberInformation(function (prevState) { return (__assign(__assign({}, prevState), { services: updatedServices })); });
    }, [allServices, subscriberInformation]);
    useEffect(function () {
        callbackServicesAction(servicesActions);
    }, [servicesActions, allServices]);
    //A função está inicializando as informações dos serviços 
    function initializeServiceOptions(product, activeSubscriberService, tier) {
        var service = (activeSubscriberService === null || activeSubscriberService === undefined) || product.id !== activeSubscriberService.content_supplier_product_id || (activeSubscriberService.status_id === canceledStatusId) ? {
            service: __assign(__assign({}, product), { status_id: 0 }),
            options: (_jsx(ServiceStatusOptions, { active: product.active, statusId: 0, expirationDate: product.expiration_date })),
            selected_action: "select",
            new_status: 0,
            provisioned: false,
            promotion: product.promotion,
        } : {
            service: __assign(__assign({}, product), { status_id: activeSubscriberService.status_id }),
            options: (_jsx(ServiceStatusOptions, { active: product.active, statusId: activeSubscriberService.status_id, expirationDate: product.expiration_date })),
            selected_action: "select",
            new_status: activeSubscriberService.status_id,
            provisioned: true,
            current_status: currentServiceStatus(activeSubscriberService.status_id),
            promotion: product.promotion,
        };
        return service;
    }
    ;
    //Função para atualizar as informações dos serviços originais (pegar status do serviço promocional pra adicionar no serviço original)
    function updatingInformationFromServices(serviceActive) {
        var _a;
        var services = (_a = updatedSubscriberInformation.services) !== null && _a !== void 0 ? _a : [];
        var servicePromotion = allServices.filter(function (item) { return item.service.group_id === serviceActive.group_id && item.service.tier === serviceActive.tier && item.service.promotion === 1; });
        var matchingService = services.find(function (service) { return service.group_id === serviceActive.group_id && service.tier === serviceActive.tier; }); //Encontrando o serviço que seja igual ao serviceActive
        if (matchingService) {
            serviceActive.status_id = matchingService.status_id;
            serviceActive.has_error = matchingService.has_error;
            serviceActive.token_status_translation = matchingService.token_status_translation;
            // serviceActive.new_status = matchingService.status_id;
            return serviceActive;
        }
        //Se tiver serviço promocional, adiciona o serviço ao serviceActive
        if (servicePromotion.length > 0) {
            serviceActive.promotion = servicePromotion.map(function (promo) { return ({
                promotion_service: promo.service
            }); });
            return serviceActive;
        }
    }
    var handleSelectChange = function (event, item) {
        var _a, _b;
        var selectedAction = typeof event === "string" ? event : event.target.value;
        setSelectedActions(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[item.service.id] = selectedAction, _a)));
        });
        item.new_status = getStatus(selectedAction); //Definindo o novo status
        if (item.new_status === item.service.status_id) { //Se o novo status for igual ao status atual do serviço redefine a ação selecionada
            item.selected_action = "select";
        }
        else {
            item.selected_action = selectedAction;
        }
        if (selectedAction === "select") {
            var updatedServicesActions = servicesActions.filter(function (service) {
                return service.content_supplier_product_id !== item.service.id;
            });
            setServicesActions(updatedServicesActions);
            delete selectedActions[item.service.id];
            setSelectedActions(selectedActions);
        }
        handleServiceChange(item, selectedAction);
        var newServicesAction = {
            action: item.selected_action,
            content_supplier_product_id: item.service.id,
            service: item.service.name,
            group_id: item.service.group_id,
            old_service_id: (_b = (_a = item.old_service) === null || _a === void 0 ? void 0 : _a.service) === null || _b === void 0 ? void 0 : _b.id,
        };
        var allServicesData = __spreadArray([], allServices, true);
        var changedServices = allServicesData.find(function (service, index) { return service.new_status === 13; });
        var updatedServicesData = allServices
            .filter(function (service) { return service.promotion !== 1; })
            .map(function (item) { return ({
            promotion: item.promotion,
            service: item.service,
            options: item.options,
            selected_action: item.selected_action,
            new_status: changedServices && changedServices.service.group_id === item.service.group_id && changedServices.service.tier !== item.service.tier ? 0 : (item.new_status === 0 ? item.service.status_id : item.new_status),
            provisioned: item.provisioned,
            current_status: item.current_status,
            old_service: item.old_service,
            content_supplier_id: item.service.content_supplier_id,
        }); });
        if (newServicesAction.action !== "select") {
            setServicesActions(function (prevActions) { return __spreadArray(__spreadArray([], prevActions, true), [newServicesAction], false); });
        }
        callbackServicesUpdate(updatedServicesData);
    };
    var handleServiceChange = function (item, selectedAction) {
        var action = selectedAction;
        var activeServicesId = (subscriberEnabledServicesData === null || subscriberEnabledServicesData === void 0 ? void 0 : subscriberEnabledServicesData.map(function (service) { return service.content_supplier_product_id; })) || [];
        //Agrupando serviços ativos por grupo
        var activeGroupServices = allServices.filter(function (service) { return subscriberEnabledServicesData === null || subscriberEnabledServicesData === void 0 ? void 0 : subscriberEnabledServicesData.some(function (subscriberService) { return subscriberService.group_id === service.service.group_id; }); });
        //Encontrando um serviço antigo no mesmo grupo
        var oldService = activeGroupServices.find(function (service) { return service.service.group_id === item.service.group_id && service.id !== item.service.id && service.service.id !== item.service.id; });
        //Verificando se o serviço atual pertence a um grupo diferente de todos os outros serviços ativos
        var isInDifferentGroup = !activeGroupServices.some(function (service) { return service.service.group_id === item.service.group_id; });
        //Lógica para lidar com a ação de troca de serviços
        if (oldService && !activeServicesId.includes(item.service.id)) {
            if (item.service.group_id === oldService.service.group_id) {
                action = "changed";
                item.old_service = oldService;
                item.new_status = 13;
                item.selected_action = action;
                activeGroupServices.forEach(function (service) {
                    if (service.service.group_id === item.service.group_id && service.service.id !== item.service.id) {
                        service.new_status = 0;
                        service.selected_action = "select";
                    }
                });
            }
            else if (isInDifferentGroup) { //Ativa o serviço normalmente se for de um grupo diferente
                item.new_status = getStatus(selectedAction);
                item.selected_action = selectedAction;
            }
        }
        else { //Se não tiver serviço antigo define a ação normalmente
            item.new_status = getStatus(selectedAction);
            item.selected_action = action;
        }
    };
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "container-services-form" }, { children: [_jsx("h3", { children: "Servi\u00E7os" }), contentSuppliersData.filter(function (contentSupplier) { if (location.pathname === "/subscribers/".concat(subscriberId, "/update")) {
                    return contentSupplier.id == contentSupplierId;
                } return true; }).map(function (item, index) {
                    return (_jsxs(Accordion, { children: [_jsx(AccordionSummary, __assign({ expandIcon: _jsx(ExpandMoreIcon, {}), "aria-controls": "panel1-content", id: "panel1-header" }, { children: _jsx("h5", { children: item.name }) })), _jsx(AccordionDetails, { children: _jsx("div", __assign({ className: "accordion-table-services" }, { children: _jsxs("table", { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Produto" }), location.pathname === "/subscribers/".concat(subscriberId, "/update") && _jsx("th", { children: "Status" }), _jsx("th", { children: "A\u00E7\u00E3o" })] }) }), _jsx("tbody", { children: allServices.length > 0 ? (allServices.filter(function (item) { return item.service.promotion !== 1 && !(item.service.tier === 2 && item.service.group_id === 1); }).map(function (item, index) {
                                                    var _a, _b;
                                                    var getStatusInfo = TokenStatusInformation(item.service.status_id);
                                                    return (_jsxs("tr", { children: [_jsxs("td", { children: [item.service.name, _jsx("br", {}), item.current_status && (item.new_status !== item.service.status_id) && (_jsxs("span", __assign({ style: { fontSize: "0.8rem", display: "flex", alignItems: "center", gap: "0.5rem", color: "rgb(255, 67, 67)" } }, { children: [_jsx(InfoIcon, { style: { fontSize: "0.8rem" } }), "Ao alterar o status do servi\u00E7o, voc\u00EA far\u00E1 altera\u00E7\u00F5es diretas no provisionamento e na cobran\u00E7a mensal do seu assinante."] }))), subscriberEnabledServicesData && subscriberEnabledServicesData.map(function (serviceError) { return (serviceError.has_error !== 0 && serviceError.content_supplier_product_id === item.service.id && (_jsxs("div", __assign({ className: "d-flex flex-column gap-0 justify-content-start align-items-start" }, { children: [_jsxs("div", __assign({ className: "gap-0" }, { children: [_jsx(ReportProblemIcon, { sx: { color: "#fa0234" } }), _jsx("p", __assign({ style: { color: "#fa0234", paddingLeft: "0.3rem" } }, { children: "ERRO " })), _jsxs("p", __assign({ style: { paddingLeft: "0.3rem" } }, { children: ["- \u00DAltima tentativa: ", _jsx("span", __assign({ style: { fontWeight: "bold" } }, { children: getStatusInfo.statusInformation.title.toUpperCase() }))] }))] })), _jsxs("p", { children: ["Algo deu errado na \u00FAltima a\u00E7\u00E3o. Tente novamente atrav\u00E9s ", _jsx("br", {}), "da tabela de ", _jsx(Link, __assign({ to: "/subscribers?document=".concat(subscriberInformation.document), className: "link-to-subscriber-error" }, { children: "Gerenciar Assinantes" })), " ou entre em contato com o nosso suporte."] })] })))); })] }), location.pathname === "/subscribers/".concat(subscriberId, "/update") && (_jsxs("td", { children: [(_a = updatedSubscriberInformation.services) === null || _a === void 0 ? void 0 : _a.map(function (subscriber) {
                                                                        if (subscriber.tier === item.service.tier && subscriber.group_id === item.service.group_id) {
                                                                            updatingInformationFromServices(item.service);
                                                                            return (_jsx("div", __assign({ id: "service-name-status", style: { backgroundColor: subscriber.icon_color } }, { children: item.service.token_status_translation }), subscriber.content_supplier_product_id));
                                                                        }
                                                                    }), ((_b = updatedSubscriberInformation.services) === null || _b === void 0 ? void 0 : _b.some(function (subscriber) { return subscriber.tier === item.service.tier && subscriber.group_id === item.service.group_id; })) === false && (_jsx("div", __assign({ id: "service-name-status", style: { backgroundColor: "#00000014", color: "#000" } }, { children: "Inativo" })))] })), _jsx("td", __assign({ style: { width: "80px" } }, { children: _jsxs("select", __assign({ value: selectedActions[item.service.id] || "select", onChange: function (e) { return handleSelectChange(e, item); } }, { children: [_jsx("option", __assign({ value: "select" }, { children: "Selecionar a\u00E7\u00E3o" })), item.options] })) }))] }, index));
                                                })) : (_jsx("tr", { children: _jsx("td", __assign({ colSpan: 3 }, { children: "Nenhum servi\u00E7o dispon\u00EDvel." })) })) })] }) })) })] }, index));
                })] })) }));
}
;
export default ServicesForm;
