var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ContentSupplierService from "../../Subscriber/services/ContentSupplierService";
import { useEffect, useState } from "react";
import Notification from "../../App/components/Notification";
import { useLocation } from "react-router-dom";
import Title from "../../App/components/Title";
function UpdateContentSupplierProducts() {
    var _a = useState([]), contentSupplierProductsList = _a[0], setContentSupplierProductsList = _a[1];
    var location = useLocation();
    useEffect(function () {
        getContentSupplierProducts(location.state.content_supplier.id);
    }, [location.state.content_supplier.id]);
    function getContentSupplierProducts(contentSupplierId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        Notification().Block.circle("[data-id=contentSuppliersProduct-grid]");
                        return [4 /*yield*/, ContentSupplierService.allProducts(contentSupplierId).then(function (res) {
                                var result = res.data.content_supplier_products;
                                setContentSupplierProductsList(result);
                            }, function (err) {
                                if (err.response.status === 403) {
                                    Notification().Notify.failure("Usuário não tem permissão para visualizar essa página!");
                                }
                                else {
                                    Notification().Notify.failure("Erro ao buscar produtos");
                                }
                            })];
                    case 1:
                        _a.sent();
                        Notification().Block.remove("[data-id=contentSuppliersProduct-grid]");
                        return [2 /*return*/];
                }
            });
        });
    }
    return (_jsxs("div", __assign({ className: "content-internal-padding" }, { children: [_jsx(Title, { text: "Gerenciar Produtos ".concat(location.state.content_supplier.name) }), _jsx("div", __assign({ className: "row admin-contentsProducts", style: { background: "transparent" } }, { children: _jsx("div", __assign({ className: "col" }, { children: _jsx("div", __assign({ className: "card", style: { border: "none", background: "transparent" } }, { children: _jsx("div", __assign({ className: "card-body", style: { background: "transparent" } }, { children: _jsx("div", __assign({ "data-id": "contentSuppliersProduct-grid", className: "box-service" }, { children: (contentSupplierProductsList !== undefined && contentSupplierProductsList.length > 0) ? (contentSupplierProductsList.map(function (content_supplier_product_data, keyNumber) { return (_jsxs("div", __assign({ style: {
                                        border: "3px solid #EA5A1C",
                                        borderRadius: "26px",
                                        fontWeight: "600",
                                        fontSize: "40px",
                                    } }, { children: [_jsx("div", { className: "service-box-header-image d-flex justify-content-end", style: {
                                                backgroundImage: "url(".concat(content_supplier_product_data.img_path, ")"),
                                                borderRadius: "22px 22px 0px 0px",
                                                width: "480px",
                                                height: "186px",
                                            } }), _jsxs("div", __assign({ style: {
                                                background: "#FFFFFF",
                                                display: "flex",
                                                justifyContent: "center",
                                                borderRadius: "0px 0px 22px 22px",
                                            } }, { children: ["R$", content_supplier_product_data.price.toFixed(2)] }))] }), keyNumber)); })) : (_jsx("div", { children: _jsxs("div", __assign({ className: "text-center" }, { children: [" ", "Nenhum produto cadastrado"] })) })) })) })) })) })) }))] })));
}
export default UpdateContentSupplierProducts;
