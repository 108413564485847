import { useState, useEffect } from 'react';
import ContentSupplierService from '../../Subscriber/services/ContentSupplierService';
import { handleServiceError } from '../components/ErrorHandler';
var useContentSuppliers = function () {
    var _a = useState([]), contentSuppliers = _a[0], setContentSuppliers = _a[1];
    var _b = useState([]), contentSuppliersProducts = _b[0], setContentSuppliersProducts = _b[1];
    var getAllContentGetSuppliersWithProducts = function () {
        ContentSupplierService.getSuppliersWithProducts()
            .then(function (res) {
            setContentSuppliers(res.data.content_suppliers);
        })
            .catch(function (err) {
            handleServiceError(err);
        });
    };
    var getContentSuppliersProducts = function (content_supplier_id) {
        ContentSupplierService.getActiveContentSupplierProducts(undefined, content_supplier_id)
            .then(function (res) {
            setContentSuppliersProducts(res.data.content_supplier_products);
        })
            .catch(function (err) {
            handleServiceError(err);
        });
    };
    useEffect(function () {
        getAllContentGetSuppliersWithProducts();
    }, []);
    return {
        contentSuppliers: contentSuppliers,
        contentSuppliersProducts: contentSuppliersProducts,
        setContentSuppliersProducts: setContentSuppliersProducts,
        getContentSuppliersProducts: getContentSuppliersProducts,
        getAllContentGetSuppliersWithProducts: getAllContentGetSuppliersWithProducts
    };
};
export default useContentSuppliers;
