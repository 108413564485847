import { canceledStatusId, changedStatusId, scheduleCancelStatusId, suspendStatusId } from "../../App/helpers/TokenStatusHelper";
export function currentServiceStatus(statusId) {
    switch (statusId) {
        case scheduleCancelStatusId:
        case canceledStatusId:
            return "deactivate";
        case suspendStatusId:
            return "suspend";
        case changedStatusId:
            return "changed";
        default:
            return "activate";
    }
}
export function getStatus(action) {
    switch (action) {
        case "activate":
        case "reactivate":
            return 1;
        case "suspend":
            return 4;
        case "deactivate":
            return 3;
        case "changed":
            return 13;
        default:
            return 0;
    }
}
