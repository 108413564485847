var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import "../styles/Footer.css";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import footerHub from "../../App/assets/hub_outline_cinza.png";
function Footer() {
    return (_jsxs("footer", __assign({ className: "border-bottom bottom" }, { children: [_jsx("div", __assign({ className: "container mt-2 " }, { children: _jsxs("div", __assign({ className: "row d-flex justify-content-center" }, { children: [_jsx("div", __assign({ className: "d-flex justify-content-center mb24" }, { children: _jsx("img", { src: footerHub, alt: "", width: 109 }) })), _jsx("div", __assign({ className: "d-flex justify-content-center provider-home-links" }, { children: _jsxs("ul", __assign({ className: "nav footer-links" }, { children: [_jsx("li", __assign({ className: "nav-item" }, { children: _jsx(Link, __assign({ to: "/contracts" }, { children: "Contrato" })) })), _jsx("li", __assign({ className: "nav-item" }, { children: _jsx("a", __assign({ href: "https://celetihub.com.br/politica-de-privacidade/", target: "_blank" }, { children: "Sua privacidade" })) })), _jsx("li", __assign({ className: "nav-item" }, { children: _jsx(Link, __assign({ to: "/faq" }, { children: "FAQs" })) })), _jsx("li", __assign({ className: "nav-item" }, { children: _jsx("a", __assign({ href: "https://celetihub.com.br", target: "_blank" }, { children: "CeletiHub" })) }))] })) })), _jsx("div", __assign({ className: "chub-copyright" }, { children: _jsx("p", { children: " \u00A9 2022 - CeletiHub" }) }))] })) })), _jsx("a", __assign({ href: "https://api.whatsapp.com/send?phone=552140201697", className: "wp-btn", target: "_blank" }, { children: _jsx(WhatsAppIcon, { className: "admin-footer-whatsapp provider-home-whatsapp" }) }))] })));
}
export default Footer;
