var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import "../styles/first-access.css";
import { Navbar } from "react-bootstrap";
import celetihubLogo2 from "../../App/assets/celetihub-logo2.png";
function SmallLogoHeader() {
    return (_jsx("header", __assign({ className: "bg-white", style: { height: "57px" } }, { children: _jsx("div", __assign({ className: "container" }, { children: _jsx(Navbar, __assign({ variant: "light", expand: "lg" }, { children: _jsx("img", { alt: "CeletiHUB Logo", src: celetihubLogo2, width: 150 }) })) })) })));
}
export default SmallLogoHeader;
