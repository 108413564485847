var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { documentFormatter } from '../../App/utils/formatter';
import { Pagination } from '@mui/material';
function MeliuzList(props) {
    return (_jsxs(_Fragment, { children: [_jsxs("table", __assign({ className: "default-table-styling" }, { children: [_jsxs("tr", { children: [_jsx("th", { children: "N\u00FAmero" }), _jsx("th", { children: "CPF" }), _jsx("th", { children: "Data de Cadastro" })] }), props.meliuzSubscribers.length > 0 ? (props.meliuzSubscribers.map(function (item, index) {
                        return (_jsxs("tr", { children: [_jsxs("td", { children: ["#", item.id] }), _jsx("td", { children: documentFormatter(item.document) }), _jsx("td", { children: item.created_at })] }, index));
                    })) : (_jsx("tr", { children: _jsx("td", __assign({ colSpan: 4, style: { textAlign: 'center', padding: '1rem' } }, { children: "N\u00E3o h\u00E1 nenhum assinante." })) }))] })), _jsx("div", __assign({ style: { display: "flex", justifyContent: "flex-end", paddingTop: "1rem" } }, { children: _jsx(Pagination, { count: Math.ceil(props.totalItems / props.perPage), page: props.currentPage, onChange: props.pageChange, color: "standard", sx: {
                        '& .MuiPaginationItem-root': {
                            color: '#ff8926',
                        },
                        '& .MuiPaginationItem-root.Mui-selected': {
                            backgroundColor: '#ff8926',
                            color: '#fff',
                        },
                        '& .MuiPaginationItem-root:hover': {
                            backgroundColor: '#ff8926',
                            color: '#fff',
                        }
                    }, showFirstButton: true, showLastButton: true }) }))] }));
}
export default MeliuzList;
