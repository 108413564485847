var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "../styles/ImportSubscribers.css";
import { CSVLink } from "react-csv";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Tooltip } from "@mui/material";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import "../../App/styles/GlobalStyle.css";
import LinearProgress from '@mui/material/LinearProgress';
import "../styles/FileCard.css";
var FileCard = function (props) {
    var _a, _b, _c, _d;
    function bytesToSize(bytes) {
        var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        if (bytes === 0)
            return "n/a";
        var i = Math.floor(Math.log(Math.abs(bytes)) / Math.log(1024));
        if (i === 0)
            return "".concat(bytes.toFixed(2)).concat(sizes[i]);
        return "".concat((bytes / Math.pow(1024, i)).toFixed(2)).concat(sizes[i]);
    }
    return (_jsxs("div", __assign({ className: "container-file-card" }, { children: [_jsxs("div", { children: [props.fileStatus === "error" || props.fileStatus === "warning" ? (_jsx("div", { children: _jsx(ReportOutlinedIcon, { sx: { fontSize: 30, color: props.fileStatus === "error" ? "#DC3545" : "#EA5A1C" }, className: "upload-icon-file-card" }) })) : (_jsx("div", { children: _jsx(UploadFileIcon, { sx: { fontSize: 30, color: "#a7a7a7" }, className: "upload-icon-file-card" }) })), _jsxs("div", __assign({ className: "file-name" }, { children: [_jsx("p", __assign({ style: { color: props.fileStatus === "error" ? "#DC3545" : props.fileStatus === "warning" ? "#EA5A1C" : "" } }, { children: props.name })), _jsx("div", { children: _jsx("span", __assign({ style: { color: props.fileStatus === "error" ? "#DC3545" : props.fileStatus === "warning" ? "#EA5A1C" : "" } }, { children: bytesToSize(props.size) })) }), props.finalProgress !== 100 ? (_jsx("div", __assign({ style: { width: props.progress + "%", marginTop: "0.5rem" } }, { children: _jsx(LinearProgress, { variant: "determinate", value: props.finalProgress, color: props.fileStatus === "error" ? "error" : props.fileStatus === "warning" ? "warning" : "primary", style: { borderRadius: '5px', height: '4px', width: '200px' } }) }))) : (_jsx("div", __assign({ style: { width: "200px", marginTop: "0.5rem" } }, { children: _jsx(LinearProgress, { variant: "determinate", value: 100, color: props.fileStatus === "error" ? "error" : props.fileStatus === "warning" ? "warning" : "primary", style: { borderRadius: '5px', height: '4px', width: '200px' } }) })))] }))] }), 
            // props.selectService === "" &&
            _jsx(_Fragment, { children: props.resultData !== undefined && (_jsx("div", __assign({ className: "infos-import-subscribers" }, { children: _jsxs("div", { children: [_jsxs("div", __assign({ style: { display: "flex", flexDirection: "column", alignItems: "center" } }, { children: [_jsx("span", __assign({ style: { color: props.fileStatus === "error" ? "#DC3545" : props.fileStatus === "warning" ? "#EA5A1C" : "" } }, { children: "Assinantes Adicionados" })), _jsxs("span", __assign({ style: { color: props.fileStatus === "error" ? "#DC3545" : props.fileStatus === "warning" ? "#EA5A1C" : "" } }, { children: [(_a = props.resultData) === null || _a === void 0 ? void 0 : _a.registered, " /", " ", (_b = props.resultData) === null || _b === void 0 ? void 0 : _b.totalSubscribers] }))] })), _jsxs("div", __assign({ style: { display: "flex", flexDirection: "column", alignItems: "center" } }, { children: [_jsx("span", __assign({ style: { color: props.fileStatus === "error" ? "#DC3545" : props.fileStatus === "warning" ? "#EA5A1C" : "" } }, { children: "Servi\u00E7os Ativados" })), _jsxs("span", __assign({ style: { color: props.fileStatus === "error" ? "#DC3545" : props.fileStatus === "warning" ? "#EA5A1C" : "" } }, { children: [(_c = props.resultData) === null || _c === void 0 ? void 0 : _c.provisioned, " /", " ", (_d = props.resultData) === null || _d === void 0 ? void 0 : _d.totalServices] }))] }))] }) }))) }), _jsxs("div", __assign({ style: { display: "flex", alignItems: "center" } }, { children: [
                    // props.selectService === "" &&
                    _jsx("div", __assign({ className: "report-error" }, { children: props.fileStatus === "warning" &&
                            props.csvData !== undefined ? (_jsx(CSVLink, __assign({ data: props.csvData, filename: props.csvName, target: "_blank", enclosingCharacter: "" }, { children: _jsx(Tooltip, __assign({ title: "Baixar relat\u00F3rio de erros" }, { children: _jsx(FileDownloadOutlinedIcon, { style: { color: "#EA5A1C" } }) })) }))) : props.fileStatus === "error" ? (_jsx(Tooltip, __assign({ title: "Ocorreu um erro" }, { children: _jsx(ReportOutlinedIcon, { style: { color: "#DC3545" } }) }))) : (null) })), _jsx(Tooltip, __assign({ title: "Excluir" }, { children: _jsx("button", __assign({ onClick: function () { props.callbackDelete(); props.closeModal(); }, className: "btn-delete-import" }, { children: _jsx(CloseOutlinedIcon, { style: { color: props.fileStatus === "error" ? "#DC3545" : props.fileStatus === "warning" ? "#EA5A1C" : "#272B3099" } }) })) }))] }))] })));
};
export default FileCard;
