import axios from 'axios';
import cookies from "./cookies";
export var api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});
api.interceptors.response.use(function (res) {
    return res;
}, function (err) {
    var _a, _b;
    if (err.response.status === 401 && window.location.pathname !== '/login') {
        if ((_b = (_a = err.response.data) === null || _a === void 0 ? void 0 : _a.error) === null || _b === void 0 ? void 0 : _b.includes("expirado")) {
            localStorage.setItem('invalid_token', 'true');
        }
        cookies.remove('token');
        cookies.remove('token_old');
        window.location.href = '/login';
        throw new Error('Não autenticado');
    }
    else {
        return Promise.reject(err);
    }
});
