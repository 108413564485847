var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, useNavigate } from "react-router-dom";
import "../../App/styles/Contracts.css";
import InfoIcon from "@mui/icons-material/Info";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import Title from "../components/Title";
function Contracts() {
    var navigate = useNavigate();
    var contractFiles = [
        {
            file: "contrato1.doc",
            size: "124kb",
            description: "Termos e condições para o uso dos serviços, garantindo a transparência e protegendo os direitos do provedor.",
        },
        {
            file: "contrato2.doc",
            size: "124kb",
            description: "Termos e condições para o uso dos serviços, garantindo a transparência e protegendo os direitos do provedor.",
        },
        {
            file: "contrato3.doc",
            size: "124kb",
            description: "Termos e condições para o uso dos serviços, garantindo a transparência e protegendo os direitos do provedor.",
        }
    ];
    return (_jsx("div", __assign({ className: "content-internal-padding" }, { children: _jsxs("div", __assign({ className: "container-fluid" }, { children: [_jsx(Title, { text: "Modelos para baixar" }), _jsxs("div", { children: [_jsxs("div", __assign({ className: "box-infos-contracts" }, { children: [_jsx(InfoIcon, {}), _jsxs("p", __assign({ style: { paddingTop: "0.9rem" } }, { children: ["Antes de adotar um contrato, leia atentamente todos os termos e condi\u00E7\u00F5es e, se tiver d\u00FAvidas, n\u00E3o hesite em entrar em contato com o seu\u00A0", _jsx("a", __assign({ id: "link-wpp-customer-sucess", href: "https://api.whatsapp.com/send?phone=552140201697", target: "_blank" }, { children: _jsx("strong", { children: "Sucesso do Cliente" }) })), "."] }))] })), _jsx("div", __assign({ className: "cards-files-download" }, { children: contractFiles.map(function (contract, index) { return (_jsxs("div", __assign({ className: "contract-download" }, { children: [_jsx(PictureAsPdfIcon, { style: { width: "2.563rem", height: "2.875rem" } }), _jsxs("span", __assign({ style: { lineHeight: "1.2rem" } }, { children: [contract.file, _jsx("br", {}), _jsx("small", __assign({ style: { color: "grey" } }, { children: contract.size }))] })), _jsx("p", __assign({ style: { marginTop: "0.90rem", color: "#575757", lineHeight: "1.25rem" } }, { children: contract.description })), _jsx(Link, __assign({ to: "/examples/subscribers-example.csv", target: "_blank", download: true }, { children: _jsx(DownloadForOfflineIcon, { style: { cursor: "pointer", color: "black" } }) }))] }), index)); }) }))] })] })) })));
}
export default Contracts;
