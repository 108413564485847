import { api } from "../../App/services/api";
function all(referenceDate, status, page, rows) {
    if (referenceDate === void 0) { referenceDate = ''; }
    if (status === void 0) { status = 0; }
    if (page === void 0) { page = 1; }
    if (rows === void 0) { rows = 20; }
    var uri = "/billings?per_page=".concat(rows, "&page=").concat(page);
    uri = referenceDate === '' ?
        uri :
        uri + "&reference_date=".concat(referenceDate);
    uri = status === 0 ?
        uri :
        uri + "&status=".concat(status);
    return api.get(uri);
}
function paginate(url) {
    return api.get(url);
}
function paginateBenefit(url, params) {
    if (params === void 0) { params = {}; }
    return api.get(url, { params: params });
}
function find(billingId, page, rows) {
    if (page === void 0) { page = 1; }
    if (rows === void 0) { rows = 20; }
    return api.get("/billings/".concat(billingId, "?per_page=").concat(rows, "&page=").concat(page));
}
function report(billingId, tokenId) {
    return api.get("/billings/".concat(billingId, "/report/").concat(tokenId));
}
function generate() {
    return api.post('/billings/generate');
}
function exportMany(billingDetailReportData) {
    return api.post('/billings/export', { billingDetailReportData: billingDetailReportData });
}
function exportAll(billingId, config) {
    if (config === void 0) { config = {}; }
    return api.get("/billings/export/".concat(billingId), config);
}
function getCurrentBill(userId) {
    return api.get("/billings/currentBill/".concat(userId));
}
function getBankSlip(billingId) {
    return api.get("/billings/".concat(billingId, "/bank-slip"), { responseType: 'arraybuffer' });
}
function generateBillingSummary(billingId) {
    return api.get("/billings/summary/".concat(billingId), { responseType: 'arraybuffer' });
}
function customerHasBenefit(billingId) {
    return api.get("CustomerProduct/checkIfCustomerHasBenefit", {
        params: { billing_id: billingId }
    });
}
function getVouchers(billing_id, page, rows) {
    if (page === void 0) { page = 1; }
    if (rows === void 0) { rows = 20; }
    return api.get("CustomerProduct/getVouchers?per_page=".concat(rows), {
        params: { billing_id: billing_id }
    });
}
function updateVoucherStatus(voucherId, newStatus) {
    return api.post("CustomerProduct/updateVoucherStatus", { voucherId: voucherId, newStatus: newStatus });
}
export default {
    all: all,
    paginate: paginate,
    find: find,
    report: report,
    generate: generate,
    exportMany: exportMany,
    exportAll: exportAll,
    getCurrentBill: getCurrentBill,
    getBankSlip: getBankSlip,
    generateBillingSummary: generateBillingSummary,
    customerHasBenefit: customerHasBenefit,
    getVouchers: getVouchers,
    updateVoucherStatus: updateVoucherStatus,
    paginateBenefit: paginateBenefit,
};
