var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { activeStatusId, canceledStatusId, checkoutStatusId, scheduleCancelStatusId, suspendStatusId } from '../../App/helpers/TokenStatusHelper';
function ServiceStatusOptions(_a) {
    var active = _a.active, statusId = _a.statusId, expirationDate = _a.expirationDate;
    var expiredFlag = expired(expirationDate);
    var options = function () {
        if (statusId === activeStatusId || statusId === checkoutStatusId) {
            return (_jsxs(_Fragment, { children: [_jsx("option", __assign({ value: "suspend" }, { children: "Suspender" })), _jsx("option", __assign({ value: "deactivate" }, { children: "Cancelar" }))] }));
        }
        else if (statusId === scheduleCancelStatusId || statusId === canceledStatusId) {
            return (_jsxs(_Fragment, { children: [_jsx("option", __assign({ value: "deactivate" }, { children: "Cancelar" })), _jsx("option", __assign({ value: "activate" }, { children: "Ativar" }))] }));
        }
        else if (statusId === suspendStatusId) {
            if (active === 0 && expiredFlag) {
                return (_jsxs(_Fragment, { children: [_jsx("option", __assign({ value: "suspend" }, { children: "Suspender" })), _jsx("option", __assign({ value: "deactivate" }, { children: "Cancelar" }))] }));
            }
            else {
                return (_jsxs(_Fragment, { children: [_jsx("option", __assign({ value: "suspend" }, { children: "Suspender" })), _jsx("option", __assign({ value: "reactivate" }, { children: "Ativar" })), _jsx("option", __assign({ value: "deactivate" }, { children: "Cancelar" }))] }));
            }
        }
        else if (statusId === 0) {
            return (_jsx(_Fragment, { children: _jsx("option", __assign({ value: "activate" }, { children: "Ativar" })) }));
        }
        else {
            return (_jsx(_Fragment, { children: _jsx("option", __assign({ disabled: true }, { children: "--------------------" })) }));
        }
    };
    return options();
}
;
function expired(expirationDate) {
    var expDate = new Date(expirationDate);
    var currentDate = new Date();
    return expDate < currentDate;
}
export default ServiceStatusOptions;
